<template>
  <v-card class="modal-create">
    <v-form ref="form">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center">Выбор сделки</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="$emit('close')">
          <img :src="require('@/assets/img/common/close-dialog.svg')"/>
        </div>
        <div class="chat-sidebar__deals">
          <div class="chat-sidebar__deal" v-for="i in deals" :key="i.id" :class="{'active': selectedDealId === i.id}" @click="$emit('selectDeal', i.id)">
            <img width="26" :src="require('@/assets/img/menu/deals.svg')" alt="">
            <div class="d-flex flex-column">
              <div class="item-page__text-field">
                <small>Название</small>
                <div class="d-flex align-center">
                  <span class="item-page__deal-name">{{ i.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "SelectDeal",
  props: {
    selectedDealId: {
      type: Number,
    },
    clientId: {
      type: Number,
    }
  },
  computed: {
    ...mapState("deals", ['deals',]),
  },
  mounted() {
    this.$emit('updateDeals');
  },
  watch: {
    clientId () {
      this.$emit('updateDeals');
    }
  }
}
</script>

<style scoped>

</style>
