<template>
  <v-navigation-drawer
      v-model="localShow"
      absolute
      right
      width="310"
      class="chat-sidebar"
      v-if="chatInfo"
  >
    <div>
      <h3>Информация о клиенте</h3>
      <div class="chat-sidebar__selected mb-5" v-if="chatInfo.client">
        <div class="chat-sidebar__headline">
          <h3>Клиент - Общее</h3>
        </div>
        <div class="chat-sidebar__selected-body">

          <div class="evi-toolbar__night mb-3" :class="{'active': chatInfo.client.is_excluded_from_analytics}">
            <v-switch
                label="Не учитывать клиента в конверсии"
                v-model="chatInfo.client.is_excluded_from_analytics"
                :readonly="!hasRight('clients.manage')"
                @change="editClientData"
            >
              <template v-slot:label>
                <span class="mr-1 text-pre">Не учитывать в конверсии</span>
                <v-menu
                    top
                    offset-y
                    open-on-hover
                >
                  <template v-slot:activator="{ on, attrs }">
                    <strong v-bind="attrs" v-on="on"><v-icon size="18" color="#289E80">mdi-information-outline</v-icon></strong>
                  </template>
                  <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                    Клиент не будет учитываться в конверсии
                    <br>
                    сотрудника\организации\подразделений\каналов продаж
                  </v-list-item>
                </v-menu>
              </template>
            </v-switch>
          </div>
          <v-autocomplete
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              class="evi-autocomplete mb-3"
              color="#44D370"
              :items="localUsers"
              v-model="responsibleUser"
              item-text="name"
              item-value="id"
              :loading="userLoading"
              @focus="loadUsers"
              :search-input.sync="userSearch"
              @input.native="searchUser(userSearch)"
              :clearable="hasRight('clients.manage')"
              item-color="green"
              hide-details
              @change="editClientData"
          >
            <template v-slot:label>
              Ответственный
            </template>
            <template v-slot:append-item>
              <div v-intersect="userIntersect"/>
            </template>
            <template v-slot:item="{item}">
              <v-avatar
                  color="#FFFFFF"
                  size="30"
                  class="user-page__avatar mr-3"
              >
                <img
                    :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
              <div class="v-list-item__title">{{ item.name }}</div>
            </template>
            <template v-slot:append>
                        <span class="evi-autocomplete__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
            </template>
          </v-autocomplete>
          <v-autocomplete
              class="evi-autocomplete mb-2"
              color="#44D370"
              :items="clientStatuses"
              v-model="clientStatus"
              item-text="name"
              item-value="id"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              :clearable="hasRight('clients.manage')"
              item-color="green"
              :key="clientStatuses.length"
              hide-details
          >
            <template v-slot:label>
              Статус клиента
            </template>
            <template v-slot:append>
              <span class="evi-autocomplete__arrow">
                  <v-icon>mdi-chevron-down</v-icon>
              </span>
            </template>
            <template v-slot:selection="{item}">
              <div class="modal-create__color-select">
                <span class="color" :style="{backgroundColor: `${item.color}`}"/>
                {{ item.name }}
              </div>
            </template>
            <template v-slot:item="{item}">
              <div class="modal-create__color-select">
                <span class="color mt-1" :style="{backgroundColor: `${item.color}`}"/>
                {{ item.name }}
              </div>
            </template>
          </v-autocomplete>
          <v-autocomplete
              class="evi-autocomplete mb-5"
              color="#44D370"
              :items="tags"
              v-model="clientTags"
              multiple
              item-text="name"
              item-value="id"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              :clearable="hasRight('clients.manage')"
              item-color="green"
              hide-details
          >
            <template v-slot:label>
              Теги
            </template>
            <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
            </template>
            <template v-slot:selection="{item}">
              <div class="evi-autocomplete__tag mr-2 mt-2"
                   :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                {{ item.name }}
              </div>
            </template>
            <template v-slot:item="{item}">
              <div class="evi-autocomplete__tag"
                   :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                {{ item.name }}
              </div>
            </template>
          </v-autocomplete>

          <v-menu
              v-model="menuNextContact"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="nextContactDateFormatted"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  label="Дата следующего контакта"
                  color="#44D370"
                  class="evi-text-field mb-5"
                  @change="editClientData"
                  hide-details
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="nextContactDate"
                @input="menuNextContact = false"
                color="#44D370"
                first-day-of-week="1"
                @change="editClientData"
            ></v-date-picker>
          </v-menu>
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="chatInfo.client.email"
              label="Email клиента"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              hide-details
          />
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="chatInfo.client.phone"
              label="Телефон клиента"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              hide-details
          />
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="chatInfo.client.country"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              hide-details
          >
            <template v-slot:label>
              Страна
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="chatInfo.client.city"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              hide-details
          >
            <template v-slot:label>
              Город
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="chatInfo.client.address"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              hide-details
          >
            <template v-slot:label>
              Адрес
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="chatInfo.client.birth"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              hide-details
              v-mask="`##.##.####`"
          >
            <template v-slot:label>
              Дата рождения
            </template>
          </v-text-field>
          <v-select
              class="evi-select mb-2"
              color="#44D370"
              v-model="chatInfo.client.gender"
              :items="genderVariants"
              label="Пол"
              item-color="green"
              item-text="name"
              item-value="value"
              @change="editClientData"
              :disabled="!hasRight('clients.manage')"
              hide-details
          >
            <template v-slot:append>
              <span class="evi-select__arrow">
                  <v-icon>mdi-chevron-down</v-icon>
              </span>
            </template>
          </v-select>
          <div class="item-page__text-field mb-2"
               v-if="chatInfo.client && (hasRight('clients.viewOwn') || hasRight('clients.viewAll'))">
            <small>Первый контакт</small>
            <div class="d-flex align-center">
              <span>{{ chatInfo.client.first_contact_date | moment('DD.MM.YYYY в HH:mm') }}</span>
            </div>
          </div>
          <div class="item-page__text-field mb-5"
               v-if="chatInfo.client && (hasRight('clients.viewOwn') || hasRight('clients.viewAll'))">
            <small>Последний контакт</small>
            <div class="d-flex align-center">
              <span>{{ chatInfo.client.last_contact_date | moment('DD.MM.YYYY в HH:mm') }}</span>
            </div>
          </div>
        </div>
        <div class="chat-sidebar__selected mb-5">
          <CustomFields :items="clientCustomFields" :fieldDisabled="!hasRight('clients.manage')"
                        typeName="Клиент"
                        :canRemoveField="hasRight('clients.manage') && hasRight('clients.viewAll') && hasRight('clients.delete')"
                        @removeField="removeField" @change="changeCustomField" @editField="editField"/>
          <div v-if="hasRight('clients.manage')" class="add-field pointer mb-10" @click="addClientFieldModal = true">
            <v-icon>mdi-plus</v-icon>
            Добавить поле
          </div>
        </div>
        <div class="chat-sidebar__selected mb-5">
          <div class="chat-sidebar__headline">
            <h3>Действия</h3>
          </div>
          <div class="chat-sidebar__selected-body pt-5 pb-5">
            <v-btn v-if="hasRight('deals.manage')" class="evi-button-green export w-100 mb-5"
                   @click="createDealModal = true">
              <v-icon size="21" color="#0E9888" class="ml-2 mr-2">mdi-plus</v-icon>
              Добавить сделку
            </v-btn>
            <v-btn v-if="hasRight('deals.manage')" class="evi-button-green export w-100"
                   @click="selectDealModal = true">
              <v-icon size="18" color="#0E9888" class="mr-2">mdi-check-circle-outline</v-icon>
              Выбрать сделку
            </v-btn>
          </div>
        </div>
      </div>
      <div v-if="deal.id" class="chat-sidebar__selected mb-5">
        <div class="chat-sidebar__headline">
          <h3>Сделка - общее</h3>
          <router-link class="chat-router-link" :to="{name: 'Deal-Page', params: {id: deal.id}}">
            Перейти
          </router-link>
        </div>
        <div class="chat-sidebar__selected-body">
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="deal.name"
              :disabled="!hasRight('deals.manage')"
              hide-details
              @change="editDealPage"
          >
            <template v-slot:label>
              Название сделки (номер)
            </template>
          </v-text-field>
          <v-autocomplete
              :readonly="!hasRight('deals.manage')"
              v-if="hasRight('deals.viewOwn') || hasRight('deals.viewAll')"
              class="evi-autocomplete mb-3"
              color="#44D370"
              :items="localUsers"
              v-model="dealExecutor"
              item-text="name"
              item-value="id"
              :loading="userLoading"
              @focus="loadUsers"
              :search-input.sync="curatorSearch"
              @input.native="searchUser(curatorSearch)"
              :clearable="hasRight('deals.manage')"
              cache-items
              item-color="green"
              hide-details
              @change="editDealPage"
          >
            <template v-slot:label>
              Исполнитель
            </template>
            <template v-slot:append-item>
              <div v-intersect="userIntersect"/>
            </template>
            <template v-slot:item="{item}">
              <v-avatar
                  color="#FFFFFF"
                  size="30"
                  class="user-page__avatar mr-3"
              >
                <img
                    :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
              <div class="v-list-item__title">{{ item.name }}</div>
            </template>
            <template v-slot:append>
              <span class="evi-autocomplete__arrow">
                  <v-icon>mdi-chevron-down</v-icon>
              </span>
            </template>
          </v-autocomplete>
          <v-autocomplete
              class="evi-autocomplete mb-3"
              color="#44D370"
              :items="localStatuses"
              v-model="deal.deal_status"
              item-text="name"
              item-value="id"
              :clearable="hasRight('deals.manage')"
              @change="editDealPage"
              return-object
              :readonly="!hasRight('deals.manage')"
              v-if="hasRight('deals.viewOwn') || hasRight('deals.viewAll')"
              item-color="green"
              hide-details
          >
            <template v-slot:label>
              Статус
            </template>
            <template v-slot:append>
                        <span class="evi-autocomplete__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
            </template>
            <template v-slot:selection="{item}">
              <div class="modal-create__color-select">
                <span class="color" :style="{backgroundColor: `${item.color}`}"/>
                {{ item.name }}
              </div>
            </template>
            <template v-slot:item="{item}">
              <div class="modal-create__color-select">
                <span class="color mt-1" :style="{backgroundColor: `${item.color}`}"/>
                {{ item.name }}
              </div>
            </template>
          </v-autocomplete>
          <v-autocomplete
              class="evi-autocomplete mb-3"
              color="#44D370"
              :items="localSubdivisions"
              v-model="deal.subdivision"
              item-text="name"
              item-value="id"
              :loading="subdivisionLoading"
              @focus="loadSubdivisions"
              :search-input.sync="subdivisionSearch"
              @input.native="searchSubdivision(subdivisionSearch)"
              :clearable="hasRight('deals.manage')"
              @change="editDealPage"
              return-object
              :readonly="!hasRight('deals.manage')"
              v-if="hasRight('deals.viewOwn') || hasRight('deals.viewAll')"
              item-color="green"
              hide-details
          >
            <template v-slot:label>
              Ответственное подразделение
            </template>
            <template v-slot:append-item>
              <div v-intersect="subdivisionIntersect"/>
            </template>
            <template v-slot:append>
              <span class="evi-autocomplete__arrow">
                  <v-icon>mdi-chevron-down</v-icon>
              </span>
            </template>
          </v-autocomplete>
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="deal.discount"
              @change="editDealPage"
              :disabled="!hasRight('deals.manage')"
              type="number"
              suffix="%"
              hide-details
          >
            <template v-slot:label>
              Скидка на продукцию
            </template>
          </v-text-field>
        </div>

        <div v-if="deal" class="chat-sidebar__selected mb-5">
          <div class="chat-sidebar__headline">
            <h3>Корзина</h3>
            <div v-if="hasRight('deals.manage')" @click="addProductModal = true" class="add-product text-pre">Добавить продукт
              <v-icon size="16">mdi-plus</v-icon>
            </div>
          </div>
          <div class="chat-sidebar__products mb-5" v-if="deal.products && deal.products.length">
            <div class="chat-sidebar__product" v-for="(item, index) of deal.products" :key="index">
              <div class="d-flex justify-space-between align-end">
                <div class="name d-flex">
                  {{ item.name }}
                  <img v-if="hasRight('deals.manage')" class="ml-1 pointer" @click="editProduct(item)"
                       :src="require('@/assets/img/chat/sidebar/settings.svg')"/>
                </div>
                <v-icon class="pointer" v-if="hasRight('deals.manage')" @click="selectProduct(item)"
                        title="Удалить продукт">mdi-close
                </v-icon>
              </div>
              <router-link class="deal-user-name" :to="{name: 'User-Page', params: {id: item.deal_product_user.id}}">
                {{ item.deal_product_user.name }}
              </router-link>
              <div class="price">
                <span class="sum">{{ item.deal_price | VMask(currencyMask) }}</span> x <span
                  class="sum">{{ item.count | VMask(currencyMask) }}</span> =
                {{ parseFloat(item.deal_price * item.count).toFixed(2) | VMask(currencyMask) }}
              </div>
            </div>
          </div>
          <div class="chat-sidebar__selected-body">
            <v-text-field
                class="evi-text-field mb-3"
                color="#44D370"
                v-model="deal.delivery_cost"
                @change="editDealPage"
                :disabled="!hasRight('deals.manage')"
                type="number"
                hide-details
            >
              <template v-slot:label>
                Стоимость доставки
              </template>
            </v-text-field>
            <div class="evi-toolbar__night mb-2" :class="{'active': deal.client_pay_delivery}">
              <v-switch
                  label="Клиент оплачивает доставку"
                  v-model="deal.client_pay_delivery"
                  :readonly="!hasRight('deals.manage')"
                  @change="editDealPage"
                  v-if="hasRight('deals.viewOwn') || hasRight('deals.viewAll')"
                  hide-details
              ></v-switch>
            </div>
            <v-text-field
                class="evi-text-field"
                color="#44D370"
                v-model="deal.prepayment"
                @change="editDealPage"
                :disabled="!hasRight('deals.manage')"
                type="number"
            >
              <template v-slot:label>
                Предоплата
              </template>
            </v-text-field>
          </div>
          <div class="chat-sidebar__selected-body">
            <div class="chat-sidebar__summary">
              <div class="chat-sidebar__summary-point">
                <span>Сумма корзины</span>
                <span class="underline"></span>
                <span class="price">{{ deal.sum | VMask(currencyMask) }}</span>
              </div>
              <div class="chat-sidebar__summary-point">
                <span>Скидка <span class="price">{{ deal.discount }}%</span></span>
                <span class="underline"></span>
                <span class="price">{{ calculateDiscount(deal.discount) | VMask(currencyMask) }}</span>
              </div>
              <div class="chat-sidebar__summary-point" v-if="deal.client_pay_delivery">
                <span>Доставка</span>
                <span class="underline"></span>
                <span class="price">{{ (deal.delivery_cost || 0) | VMask(currencyMask) }}</span>
              </div>
              <div class="chat-sidebar__summary-point">
                <span class="sum">Итого</span>
                <span class="underline sum"></span>
                <span class="price">{{ deal.total_sum | VMask(currencyMask) }}</span>
              </div>
              <div class="chat-sidebar__summary-point">
                <span>Предоплата</span>
                <span class="underline"></span>
                <span class="price">{{ deal.prepayment | VMask(currencyMask) }}</span>
              </div>
              <div class="chat-sidebar__summary-point">
                <span>При получении</span>
                <span class="underline"></span>
                <span class="price">{{ deal.payment_upon_receipt | VMask(currencyMask) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="deal" class="chat-sidebar__selected mb-5">
          <Delivery :dealId="deal.id" :canEdit="hasRight('deals.manage')" :delivery="deal.delivery || {}" />
        </div>
        <div class="chat-sidebar__selected mb-5">
          <CustomFields :items="dealCustomFields" :fieldDisabled="!hasRight('deals.manage')"
                        typeName="Сделка"
                        :canRemoveField="hasRight('deals.manage') && hasRight('deals.viewAll') && hasRight('deals.delete')"
                        @removeField="removeDealField" @change="changeDealCustomField" @editField="editDealField"/>
          <div v-if="hasRight('deals.manage')" class="add-field pointer mb-10" @click="addDealFieldModal = true">
            <v-icon>mdi-plus</v-icon>
            Добавить поле
          </div>
        </div>
      </div>
      <div v-if="chatInfo.client" class="chat-sidebar__selected mb-5">
        <div class="chat-sidebar__headline">
          <h3>Соц. сети клиента</h3>
        </div>
        <div class="chat-sidebar__selected-body">
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="chatInfo.client.vk_id"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              hide-details
          >
            <template v-slot:label>
              VK ID
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="chatInfo.client.vk_url"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              hide-details
          >
            <template v-slot:label>
              Ссылка на страницу VK
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="chatInfo.client.vk_login"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              hide-details
          >
            <template v-slot:label>
              Имя VK
            </template>
          </v-text-field>

          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="chatInfo.client.instagram_id"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              hide-details
          >
            <template v-slot:label>
              Instagram ID
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="chatInfo.client.instagram_url"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              hide-details
          >
            <template v-slot:label>
              Ссылка на страницу Instagram
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="chatInfo.client.instagram_login"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              hide-details
          >
            <template v-slot:label>
              Логин Instagram
            </template>
          </v-text-field>

          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="chatInfo.client.telegram_id"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              hide-details
          >
            <template v-slot:label>
              Telegram ID
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="chatInfo.client.telegram_url"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              hide-details
          >
            <template v-slot:label>
              Ссылка на чат Telegram
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="chatInfo.client.telegram_login"
              @change="editClientData"
              :readonly="!hasRight('clients.manage')"
              v-if="hasRight('clients.viewOwn') || hasRight('clients.viewAll')"
              hide-details
          >
            <template v-slot:label>
              Логин Telegram
            </template>
          </v-text-field>
        </div>
      </div>
      <div class="chat-sidebar__selected mb-5"
           v-if="chatInfo.client && chatInfo.client.vk_ref_source || chatInfo.client.vk_ref">
        <div class="chat-sidebar__headline">
          <h3>VK UTM</h3>
        </div>
        <div class="chat-sidebar__selected-body">
          <div class="item-page__text-field mb-2"
               v-if="chatInfo.client && (hasRight('clients.viewOwn') || hasRight('clients.viewAll'))">
            <small>Название кампании (ref)</small>
            <div class="d-flex align-center">
              <span>{{ chatInfo.client.vk_ref }}</span>
            </div>
          </div>
          <div class="item-page__text-field"
               v-if="chatInfo.client && (hasRight('clients.viewOwn') || hasRight('clients.viewAll'))">
            <small>Название рекламной площадки (ref_source)</small>
            <div class="d-flex align-center">
              <span>{{ chatInfo.client.vk_ref_source }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="deleteProductModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление продукта из сделки
        </v-card-title>
        <v-card-text>Вы действительно хотите убрать продукт {{ selectedProduct.name }} из сделки?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="deleteProductModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="removeProduct"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="addProductModal"
        max-width="612px"
    >
      <AddProduct @close="addProductModal = false" :dealId="deal.id" :dealProducts="deal.products"
                  :editMode="editModeProduct" :editedProduct="selectedProduct" @editModeOff="editModeProductOff"/>
    </v-dialog>
    <v-dialog
        v-model="createBillModal"
        max-width="612px"
    >
      <CreateBill :currentDeal="selectedDeal" @close="createBillModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="addClientFieldModal"
        max-width="612px"
    >
      <CreateField @createField="createClientCustomFields" @close="addClientFieldModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="createDealModal"
        max-width="612px"
    >
      <CreateDeal :currentClient="chatInfo.client" @updateDeals="getDeals" @close="createDealModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="selectDealModal"
        max-width="612px"
    >
      <SelectDeal :currentClient="chatInfo.client" @updateDeals="getDeals" @selectDeal="getDealLocal" @close="selectDealModal = false" :selectedDealId="deal.id" :clientId="chatInfo.client.id"/>
    </v-dialog>
    <v-dialog
        v-model="addDealFieldModal"
        max-width="612px"
    >
      <CreateField @createField="createDealCustomFields" @close="addDealFieldModal = false"/>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {eventBus} from '../../main';
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import {OpenPagesMixin} from "@/mixins/OpenPagesMixin";
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";
import {SubdivisionAutocompleteRequestsMixin} from "@/mixins/autocomplete/SubdivisionAutocompleteRequestsMixin";
import {hasRight} from '@/utils/access/hasRight';
import CreateBill from "../bills/CreateBill";
import CustomFields from "../clients/CustomFields";
import CreateField from "../clients/CreateField";
import AddProduct from "@/components/deals/AddProduct";
import Delivery from "@/components/deals/Delivery";
import CreateDeal from "@/components/deals/CreateDeal";
import SelectDeal from "@/components/chat/SelectDeal";

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowNegative: true,
});

export default {
  name: "ClientSidebar",
  mixins: [OpenPagesMixin, UserAutocompleteRequestsMixin, SubdivisionAutocompleteRequestsMixin],
  components: {SelectDeal, CreateDeal, Delivery, AddProduct, CreateField, CustomFields, CreateBill},
  props: {
    client: {
      type: Object
    },
    showClientSidebar: {
      type: Boolean
    }
  },
  data: () => ({
    createDealModal: false,
    selectDealModal: false,
    localShow: false,
    selectedDeal: null,
    clientStatus: null,
    clientTags: null,
    responsibleUser: null,
    createBillModal: false,
    addClientFieldModal: false,
    addDealFieldModal: false,
    nextContactDate: '',
    menuNextContact: false,
    addProductModal: false,
    deleteProductModal: false,
    editModeProduct: false,
    selectedProduct: {},
    dealExecutor: null,
    currencyMask,
    genderVariants: [
      {value: 'm', name: 'Мужской',},
      {value: 'f', name: 'Женский',},
    ]
  }),
  computed: {
    ...mapState("deals", ['deal', 'dealCustomFields']),
    ...mapState("user", ['profile',]),
    ...mapState("users", ['subdivisions']),
    ...mapState("deal-statuses", ["statuses",]),
    ...mapState("chat", ["chatInfo"]),
    ...mapState("tags", ["tags"]),
    ...mapState("clients", ['clientCustomFields']),
    clientStatuses() {
      return this.$store.state['client-statuses'].statuses;
    },
    nextContactDateFormatted() {
      if (this.nextContactDate) {
        return this.$moment(this.nextContactDate).format('DD.MM.YYYY');
      }
      return '';
    },
    localUsers() {
      let array = [];
      if (this.users.length) {
        array = this.users;
      }
      if (this.client.responsible_user) {
        array.push(this.client.responsible_user)
      }
      if (this.deal && this.deal.executor) {
        array.push(this.deal.executor)
      }
      return array;
    },
    localSubdivisions() {
      let array = [];
      if (this.subdivisions.length) {
        array = this.subdivisions;
      } else if (this.deal && this.deal.subdivision) {
        array.push(this.deal.subdivision)
      }
      return array;
    },
    localStatuses() {
      let array = [];
      if (this.statuses.length) {
        array = this.statuses;
      } else {
        if (this.selectedDeal && this.selectedDeal.deal_status) {
          array.push(this.selectedDeal.deal_status)
        }
        if (this.client.last_deal && this.client.last_deal.deal_status) {
          array.push(this.client.last_deal.deal_status)
        }
      }
      return array;
    }
  },
  methods: {
    ...mapActions({
      getDealsPages: 'deals/getDealsPagesWithoutLoading',
      getDeal: 'deals/getDealWithoutLoading',
      clearDeal: 'deals/clearDeal',
      getStatuses: 'deal-statuses/getStatusesBy',
      editDeal: 'deals/editDealWithoutLoading',
      getClientLastDeal: 'deals/getClientLastDeal',
      deleteDealCustomFields: 'deals/deleteDealCustomFields',
      getDealCustomFields: 'deals/getDealCustomFields',
      createDealCustomFields: 'deals/createDealCustomFields',
      editClient: 'clients/editClientWithoutLoading',
      getClientStatuses: 'client-statuses/getStatusesBy',
      getTags: 'tags/getItemsBy',
      updateChatInfoFields: 'chat/updateChatInfoFields',
      getClientCustomFields: 'clients/getClientCustomFields',
      deleteClientCustomFields: 'clients/deleteClientCustomFields',
      createClientCustomFields: 'clients/createClientCustomFields',
      deleteProduct: 'deals/deleteProduct',
      editDealCustomFields: 'deals/editDealCustomFields',
      editClientCustomFields: 'clients/editClientCustomFields',
    }),
    hasRight: hasRight,
    calculateDiscount(percent) {
      return this.deal.sum * (percent / 100) || 0;
    },
    selectProduct(item) {
      this.selectedProduct = item;
      this.deleteProductModal = true;
    },
    editModeProductOff () {
      this.editModeProduct = false;
      this.selectedProduct = {};
    },
    removeProduct() {
      let payload = {
        dealId: this.deal.id,
        data: {
          rel_id: this.selectedProduct.rel_id,
          product_id: this.selectedProduct.id,
        },
      };

      this.deleteProduct(payload).then(() => {
        this.deleteProductModal = false;
      });
    },
    editProduct(item) {
      this.selectedProduct = item;
      this.editModeProduct = true;
      this.addProductModal = true;
    },
    async getDealLocal (id) {
      await this.getDeal(id);
      await this.getDealCustomFields();
    },
    getDeals() {
      if (this.hasRight('deals.viewAll')) {
        this.getDealsPages({
          per_page: 20,
          page: 1,
          'filter[client_id]': this.chatInfo.client.id,
        });
      } else if (this.hasRight('deals.viewOwn')) {
        this.getDealsPages({
          per_page: 20,
          page: 1,
          'filter[client_id]': this.chatInfo.client.id,
          'filter[executor_id]': this.profile.id,
        });
      }
    },
    selectDeal(item) {
      this.selectedDeal = item;
    },
    editDealPage() {
      let payload = {
        name: this.deal.name,
        client_id: this.deal.client.id,
        executor_id: this.dealExecutor,
        id: this.deal.id,
        subdivision_id: this.deal.subdivision.id,
        deal_status_id: this.deal.deal_status.id,
        discount: this.deal.discount,
        delivery_cost: this.deal.delivery_cost,
        prepayment: this.deal.prepayment,
        client_pay_delivery: this.deal.client_pay_delivery,
      };
      this.editDeal(payload);
    },
    editClientData() {
      let payload =
          {
            email: this.chatInfo.client.email,
            id: this.chatInfo.client.id,
            name: this.chatInfo.client.name,
            phone: this.chatInfo.client.phone,
            country: this.chatInfo.client.country,
            city: this.chatInfo.client.city,
            birth: this.chatInfo.client.birth,
            address: this.chatInfo.client.address,
            is_excluded_from_analytics: this.chatInfo.client.is_excluded_from_analytics,
            tags: this.clientTags,
            client_status_id: this.clientStatus,
            gender: this.chatInfo.client.gender,
            next_contact_date: this.nextContactDate,
            responsible_user_id: this.responsibleUser,
            //соц сети
            vk_id: this.chatInfo.client.vk_id,
            vk_url: this.chatInfo.client.vk_url,
            vk_login: this.chatInfo.client.vk_login,
            instagram_id: this.chatInfo.client.instagram_id,
            instagram_url: this.chatInfo.client.instagram_url,
            instagram_login: this.chatInfo.client.instagram_login,
            telegram_id: this.chatInfo.client.telegram_id,
            telegram_url: this.chatInfo.client.telegram_url,
            telegram_login: this.chatInfo.client.telegram_login,
          };
      this.editClient(payload).then((response) => {
        this.updateChatInfoFields(response.data.response)
      });
    },
    setClientInfo(val) {
      if (val.client) {
        this.clientTags = val.client.tags.map(i => i.id);
      }
      if (val.client && val.client.client_status) {
        this.clientStatus = val.client.client_status.id;
      }
      if (val.client && val.client.next_contact_date) {
        this.nextContactDate = this.$moment(val.client.next_contact_date).format('YYYY-MM-DD');
      }
      if (val.client && val.client.responsible_user) this.responsibleUser = val.client.responsible_user.id;
    },
    removeField(i) {
      this.deleteClientCustomFields({id: i.id, index: i.index})
    },
    editField(i) {
      this.editClientCustomFields(i)
    },
    editDealField(i) {
      this.editDealCustomFields(i)
    },
    removeDealField(i) {
      this.deleteDealCustomFields({id: i.id, index: i.index})
    },
    changeCustomField(val) {
      if (val.value) {
        let payload = {
          id: this.chatInfo.client.id,
          custom_fields: [val]
        };
        this.editClient(payload);
      }
    },
    changeDealCustomField(val) {
      if (val.value) {
        let payload = {
          id: this.deal.id,
          custom_fields: [val]
        };
        this.editDeal(payload);
      }
    },
    updateDealCustomFields (val) {
      const deal = this.deal || this.chatInfo.client.last_deal;
      // значения кастомных полей устанавливается тут
      if (deal && deal.custom_fields) {
        deal.custom_fields.forEach(i => {
          let field = val.find(f => f.id === i.id);
          if (field) {
            if (field.type === 'datetime') {
              field.value = `${this.$moment(i.value).format('DD.MM.YYYY')} ${this.$moment(i.value).format('HH:mm')}`;
            } else {
              field.value = i.value;
            }
          }
        });
      }
    },
  },
  watch: {
    showClientSidebar(val) {
      this.localShow = val;
      if (val && this.client.last_deal) {
        if (this.deal.client?.id !== this.chatInfo.client?.id) {
          this.getDealLocal(this.client.last_deal.id);
        }
      }
    },
    localShow(val) {
      eventBus.$emit('showClientSidebarEvent', val);
    },
    'chatInfo': {
      handler: function (val) {
        this.selectedDeal = null;
        this.setClientInfo(val);

        this.clearDeal();

        this.getClientCustomFields();
        this.getDealCustomFields();
      },
      deep: true,
      immediate: true
    },
    'clientCustomFields': {
      handler: function (val) {
        // значения кастомных полей устанавливается тут
        if (this.chatInfo.client.custom_fields) {
          this.chatInfo.client.custom_fields.forEach(i => {
            let field = val.find(f => f.id === i.id);
            if (field) {
              if (field.type === 'datetime') {
                field.value = `${this.$moment(i.value).format('DD.MM.YYYY')} ${this.$moment(i.value).format('HH:mm')}`;
              } else {
                field.value = i.value;
              }
            }
          });
        }
      },
      deep: true,
      immediate: true
    },
    'dealCustomFields': {
      handler: function (val) {
        this.updateDealCustomFields(val);
      },
      deep: true,
      immediate: true
    },
    deal (val) {
      if (val && val.executor) {
        this.dealExecutor = val.executor.id;
        this.updateDealCustomFields(this.dealCustomFields);
      }
    }
  },
  mounted() {
    this.getStatuses();
    this.setClientInfo(this.chatInfo);
  },
}
</script>

<style lang="scss" scoped>
.chat-sidebar {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: none;

  h3 {
    padding: 25px;
    font-size: 12px !important;
  }

  &__deal-name {
    padding: 24px;

    h3 {
      padding: 0;
    }
  }

  hr {
    background: #F7F7F7;
    height: 2px;
    max-height: 2px;
  }

  &__actions {
    padding: 0 24px;
    width: 100%;
    //position: absolute;
    bottom: 43px;

    .evi-button-green {
      width: 100%;
    }
  }

  &__headline {
    border-radius: 9px 9px 0 0;
    border: 1px solid #DBE0E5;
    background: #F7F7F7;
    display: flex;
    justify-content: space-between;
  }

  .chat-router-link {
    font-size: 12px;
    text-decoration: none;
    padding: 4px 17px;
    color: #008A80;
  }

  &__selected {
    padding: 0;
    border-radius: 10px;
    border: 1px solid #DBE0E5;
    background: var(--white, #FFF);
    /* shaaaa */
    box-shadow: 0 8px 8px -4px rgba(34, 37, 57, 0.03), 0px 20px 24px -4px rgba(34, 37, 57, 0.08);
    margin: 0 11px;

    .add-product {
      cursor: pointer;
      color: #808386;
      font-size: 12px;
      padding: 2px 6px;
    }

    h3 {
      padding: 4px 17px;
      font-size: 14px;
      text-transform: uppercase;
    }

    &-body {
      padding: 8px 16px;
    }
  }

  &__product {
    border-bottom: 1px solid #DCE1E6;
    padding: 5px 17px;

    .name {
      color: $evi-headline-color;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
    }

    .price {
      color: #808386;
      font-size: 12px;
      margin-top: 5px;
    }

    .sum {
      color: $evi-headline-color;
      font-weight: 600;
    }

    .deal-user-name {
      color: #0E9888;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
    }
  }

  &__summary {
    width: 100%;

    span {
      font-size: 12px;
      color: #808386;
      white-space: nowrap;
    }

    .price {
      color: rgba(0, 0, 0, 0.87);
      font-weight: 600;
    }

    .sum {
      color: rgba(0, 0, 0, 0.87);
      font-weight: 600;
    }

    &-point {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .underline {
        content: '';
        width: 100%;
        margin-right: 10px;
        margin-left: 10px;
        display: block;
        border-bottom: 1px solid #DCE1E6;
        margin-bottom: 5px;
      }
    }
  }

  .item-page {
    &__deal-name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
  }
}
</style>
