<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Добавление сделки</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')"/>
        </div>
        <v-col
            cols="12"
        >
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="name"
              hint="Если не заполнять поле, присвоится номер"
              persistent-hint
          >
            <template v-slot:label>
              Название сделки (номер)
            </template>
          </v-text-field>
          <v-autocomplete
              class="evi-autocomplete mb-5"
              color="#44D370"
              :items="localClients"
              v-model="client"
              item-text="name"
              item-value="id"
              :rules="clientRules"
              :loading="clientsLoading"
              @focus="loadClients"
              :search-input.sync="clientSearch"
              @input.native="searchClient(clientSearch)"
              clearable
              item-color="green"
          >
            <template v-slot:label>
              Клиент
              <span class="important">*</span>
            </template>
            <template v-slot:append-item>
              <div v-intersect="clientIntersect"/>
            </template>
            <template v-slot:item="{item}">
              <v-avatar
                  color="#FFFFFF"
                  size="30"
                  class="user-page__avatar mr-3"
              >
                <img
                    :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
              <div class="v-list-item__title">{{ item.name }}</div>
            </template>
            <template v-slot:append>
                        <span class="evi-autocomplete__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
            </template>
          </v-autocomplete>
          <v-autocomplete
              class="evi-autocomplete mb-5"
              color="#44D370"
              :items="localUsers"
              v-model="executor"
              item-text="name"
              item-value="id"
              :rules="executorRules"
              :loading="userLoading"
              @focus="loadUsers"
              :search-input.sync="userSearch"
              @input.native="searchUser(userSearch)"
              clearable
              item-color="green"
          >
            <template v-slot:label>
              Исполнитель
              <span class="important">*</span>
            </template>
            <template v-slot:append-item>
              <div v-intersect="userIntersect"/>
            </template>
            <template v-slot:item="{item}">
              <v-avatar
                  color="#FFFFFF"
                  size="30"
                  class="user-page__avatar mr-3"
              >
                <img
                    :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
              <div class="v-list-item__title">{{ item.name }}</div>
            </template>
            <template v-slot:append>
                        <span class="evi-autocomplete__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
            </template>
          </v-autocomplete>
          <v-autocomplete
              class="evi-autocomplete mb-5"
              color="#44D370"
              :items="statuses"
              v-model="status"
              item-text="name"
              item-value="id"
              :rules="statusRules"
              clearable
              item-color="green"
          >
            <template v-slot:label>
              Статус
              <span class="important">*</span>
            </template>
            <template v-slot:append>
                        <span class="evi-autocomplete__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
            </template>
            <template v-slot:selection="{item}">
              <div class="modal-create__color-select">
                <span class="color" :style="{backgroundColor: `${item.color}`}"/>
                {{ item.name }}
              </div>
            </template>
            <template v-slot:item="{item}">
              <div class="modal-create__color-select">
                <span class="color mt-1" :style="{backgroundColor: `${item.color}`}"/>
                {{ item.name }}
              </div>
            </template>
          </v-autocomplete>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          Добавить сделку
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {ClientAutocompleteRequestsMixin} from "@/mixins/autocomplete/ClientAutocompleteRequestsMixin";
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";

export default {
  name: "CreateDeal",
  mixins: [ClientAutocompleteRequestsMixin, UserAutocompleteRequestsMixin],
  props: {
    currentClient: {
      type: Object,
    },
    selectedStatus: {
      type: Number,
    },
  },
  data: () => ({
    valid: true,
    client: null,
    status: null,
    executor: null,
    name: '',
    nameRules: [(v) => !!v || 'Укажите название'],
    clientRules: [(v) => !!v || 'Выберите клиента'],
    statusRules: [(v) => !!v || 'Выберите статус'],
    executorRules: [(v) => !!v || 'Выберите исполнителя'],
  }),
  computed: {
    ...mapState("user", ['profile']),
    ...mapState("deal-statuses", ["statuses",]),
    localClients() {
      if (this.clients.length) {
        return this.clients;
      } else if (this.currentClient) {
        return [this.currentClient];
      }
      return [];
    },
    localUsers () {
      if (this.users.length === 0) {
        return [this.profile];
      }
      return this.users;
    }
  },
  methods: {
    ...mapActions({
      createDeal: 'deals/createDeal',
      getStatuses: 'deal-statuses/getStatusesBy',
    }),
    validate() {
      return !!this.$refs.form.validate();
    },
    create() {
      if (this.validate()) {
        let payload = {
          client_id: this.client,
          deal_status_id: this.status,
          executor_id: this.executor,
        };
        if (this.name) {
          payload.name = this.name;
        }

        this.createDeal(payload).then(() => {
          this.$emit('updateDeals');
          this.closeDialog();
        });
      }
    },
    closeDialog() {
      this.$emit('close');
      this.name = '';
      this.client = null;
      this.status = this.selectedStatus;
      this.executor = null;
      this.$refs.form.resetValidation();

      if (this.currentClient) {
        this.client = this.currentClient.id;
      }
    },
  },
  mounted() {
    if (this.currentClient) {
      this.client = this.currentClient.id;
    }
    if (this.selectedStatus) {
      this.status = this.selectedStatus;
    }
    this.getStatuses();
    // console.warn(this.client)
  },
  watch: {
    selectedStatus (val) {
      if (val) {
        this.status = val;
      }
    },
    'currentClient': {
      handler (val) {
        if (val) this.client = val.id;
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>

<style scoped>

</style>
