<template>
<div class="chat-wrapper">
    <Messages/>
</div>
</template>

<script>
import Messages from "../../components/chat/Messages";
export default {
    name: "ChatWrapper",
    components: {Messages},
}
</script>

<style lang="scss" scoped>
.chat-wrapper {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}
</style>
