<template>
    <v-card class="modal-create">
        <v-form ref="form">
            <v-card-title class="d-flex justify-center">
                <span class="text-h5 text-center mb-11">Редактирование участников беседы</span>
            </v-card-title>
            <v-card-text class="modal-create__form">
                <div class="close-dialog" @click="closeDialog">
                    <img :src="require('@/assets/img/common/close-dialog.svg')"/>
                </div>
                <v-col
                        cols="12"
                >
                    <v-autocomplete
                            class="evi-autocomplete mb-7"
                            color="#44D370"
                            v-model="members"
                            :items="localUsers"
                            item-text="name"
                            item-value="id"
                            multiple
                            :loading="userLoading"
                            @focus="loadUsers"
                            :search-input.sync="userSearch"
                            @input.native="searchUser(userSearch)"
                            cache-items
                            clearable
                            item-color="green"
                    >
                        <template v-slot:label>
                            Выберите пользователей
                        </template>
                        <template v-slot:append-item>
                            <div v-intersect="userIntersect"/>
                        </template>
                        <template v-slot:selection="{index}">
                            <div v-if="index === 0">
                                Выбрано {{members.length}}
                            </div>
                        </template>
                        <template v-slot:item="{item}">
                            <v-avatar
                                    color="#FFFFFF"
                                    size="30"
                                    class="user-page__avatar mr-3"
                            >
                                <img
                                        :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                                        alt=""
                                >
                            </v-avatar>
                            <div class="v-list-item__title">{{item.name}}</div>
                        </template>
                        <template v-slot:append>
                        <span class="evi-select__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-card-text>
        </v-form>
    </v-card>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";

    export default {
        name: "GroupMembers",
        mixins: [UserAutocompleteRequestsMixin],
        props: {
            groupMembersModal: {
                type: Boolean,
            }
        },
        data() {
            return {
                members: [],
            }
        },
        computed: {
            ...mapState("chat", ["chatInfo"]),
            localUsers() {
                let array = [...this.chatInfo.users];
                this.users.forEach(i => {
                    if (!this.members.some(e => e.id === i.id)) {
                        array.push(i);
                    }
                });
                return array;
            }
        },
        methods: {
            ...mapActions({
                addUserToGroupChat: 'chat/addUserToGroupChat',
                removeUserFromGroupChat: 'chat/removeUserFromGroupChat',
            }),
            closeDialog() {
                this.$emit('close');
            },
            initMembers(val) {
                val.forEach(i => {
                    if (!this.members.some(el => el === i.id))
                        this.members.push(i.id);
                });
            },
        },
        mounted() {
            this.initMembers(this.chatInfo.users);
        },
        watch: {
            chatInfo(val) {
                this.members = [];
                this.initMembers(val.users);
            },
            members: {
                handler: function (val, oldVal) {
                    if (this.groupMembersModal && val.length && oldVal.length) {
                        val.forEach(i => {
                            // Добавили пользователя
                            if (!oldVal.some(o => o === i)) {
                                this.addUserToGroupChat({chat_id: this.chatInfo.id, user_id: i});
                            }
                        });
                        if (oldVal) {
                            oldVal.forEach(i => {
                                // Удалили пользователя
                                if (!val.some(o => o === i)) {
                                    this.removeUserFromGroupChat({chat_id: this.chatInfo.id, user_id: i});
                                }
                            });
                        }
                    }
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
