<template>
  <div class="messages">
    <div class="messages__header">
      <div class="messages__companion d-flex align-center" v-if="selectedMessages.length">
        <v-btn class="evi-button-green evi-button-green--fill mr-4" @click="forwardMessagesModal = true">
          Переслать {{selectedMessages.length}}
        </v-btn>
        <v-btn class="evi-button-green evi-button-green--fill" @click="selectedMessages = []">
          Отмена
        </v-btn>
      </div>
      <div class="messages__companion" v-else>
        <div class="name d-flex">
          <div @click="openCompanionPage" class="companion-name">
            {{ chatName }}
          </div>
          <div class="messages__integration ml-2" v-if="chatInfo.client && chatInfo.client.integration"
               @click="openIntPage(chatInfo.client.integration.id)">
            (
            <img width="20"
                 :src="require(`@/assets/img/integrations/${chatInfo.client.integration.type}.svg`)"
                 alt=""/>
            <span class="text-truncate integration-name"
                  :title="chatInfo.client.integration.name">{{ chatInfo.client.integration.name }}</span>
            )
          </div>
        </div>
        <small v-if="chatInfo.last_message && chatInfo.subtype !== 'group'">Последняя активность в {{
            chatInfo.last_message.created_at |
                moment('LT, D MMMM YYYY')
          }}</small>
        <div v-else-if="chatInfo.subtype === 'group'" @click="groupMembersModal = true"
             class="group-info d-flex align-center">
          {{ returnGroupMembersCount(chatInfo.users.length) }}
        </div>
        <small v-else>Нет данных об активности в диалоге</small>
      </div>
      <v-menu v-if="!selectedMessages.length" offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <div class="messages__dots" v-on="on" v-bind="attrs">
            <v-icon>mdi-dots-horizontal</v-icon>
          </div>
        </template>
        <v-list dense class="settings-menu">
          <v-list-item-group>
            <v-list-item @click="groupMembersModal = true" v-if="chatInfo.subtype === 'group'">
              <v-list-item-icon class="evi-sidebar__icon">
                <img :src="require('@/assets/img/chat/plus.svg')" alt="">
              </v-list-item-icon>
              <v-list-item-title>Добавить участников</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="chatInfo.client" @click="createReminderModal = true">
              <v-list-item-icon class="mr-3">
                <img :src="require('@/assets/img/chat/reminder.svg')" alt="">
              </v-list-item-icon>
              <v-list-item-title>
                Создать напоминание
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="chatInfo.client" @click="changeUserChatModal = true">
              <v-list-item-icon class="mr-3">
                <img :src="require('@/assets/img/chat/give-chat.svg')" alt="">
              </v-list-item-icon>
              <v-list-item-title>
                Передать диалог
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="unpin(chatInfo.id)" v-if="chatInfo.is_pinned">
              <v-list-item-icon class="evi-sidebar__icon">
                <img :src="require('@/assets/img/chat/unpin.svg')" alt="">
              </v-list-item-icon>
              <v-list-item-title>Убрать из избранного</v-list-item-title>
            </v-list-item>
            <v-list-item @click="pin(chatInfo.id)" v-else>
              <v-list-item-icon class="evi-sidebar__icon">
                <img :src="require('@/assets/img/chat/pin.svg')" alt="">
              </v-list-item-icon>
              <v-list-item-title>Добавить в избранное</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>
    <div id="messagesWindow" class="messages__window"
         @scroll="handleScroll">
      <MessageItem
          v-for="(m, index) in localMessages" :key="m.id"
          :data="m"
          :localMessages="localMessages"
          :index="index"
          @scrollToBottom="scrollToBottom"
          @downloadFile="downloadFile"
          @openFileContent="openFileContent"
          @openFullImage="openFullImage"
          @selectMessage="selectMessage"
          @deselectMessage="deselectMessage"
          @editMessage="editMessageLocal"
          @removeMessage="removeChatMessage"
          :selectedMessages="selectedMessages"
          :selectedMessageMode="!!selectedMessages.length"
      />
    </div>
    <div class="messages__text">
      <v-progress-linear
          indeterminate
          color="green"
          class="progress-message"
          v-if="progressMessage"
      ></v-progress-linear>
      <v-menu :value="scriptsShow" attach=".messages__text">
        <template v-slot:activator="{ attrs }">
          <v-textarea
              no-resize
              class="chat-textarea"
              color="#44D370"
              :placeholder="isOwnChat ? 'Напишите сообщение...' : 'Вы не исполнитель чата.'"
              v-model="message"
              auto-grow
              rows="1"
              autofocus
              v-bind="attrs"
              @keydown.enter.exact.prevent="editMessageMode ? send(editMessage) : send(sendMessage)"
              ref="chatarea"
              :disabled="!isOwnChat"
          >
            <template v-slot:append>
              <EmojiPicker @pushEmoji="pushEmoji"/>
              <v-icon color="#219653" class="pointer ml-5" @click="send(editMessage)" v-if="editMessageMode">mdi-check-circle-outline</v-icon>
              <v-icon color="#219653" class="pointer ml-5" @click="send(sendMessage)" v-else>mdi-send</v-icon>
            </template>
            <template v-slot:append-outer>
              <div class="messages__file-attachments" v-if="files.length">
                <div class="messages__edit-mode" v-if="editMessageMode">
                  Редактирование
                  <v-icon class="close" size="20" @click="stopEditMode()">mdi-close</v-icon>
                </div>
                <div class="messages__file-attachment" v-for="(item, index) in files" :key="index">
                  {{ item.name || item.type }}
                  <v-icon class="close" size="20" @click="removeFile(index)">mdi-close</v-icon>
                </div>
              </div>
              <div class="messages__edit-mode" v-if="editMessageMode">
                Редактирование
                <v-icon class="close" size="20" @click="stopEditMode()">mdi-close</v-icon>
              </div>
              <div class="messages__edit-mode" v-else-if="localForwardInfo.chat_messages">
                {{localForwardInfo.chat_messages.length + ' ' + returnForwardMessagesCount(localForwardInfo.chat_messages.length)}}
                <v-icon class="close" size="20" @click="clearForwardInfo">mdi-close</v-icon>
              </div>
            </template>
            <template v-slot:prepend-inner>
              <v-file-input
                  v-if="chatInfo.client && chatInfo.client.integration && chatInfo.client.integration.type !== 'instagram'"
                  hide-input
                  class="messages__files"
                  v-model="files"
                  multiple
                  :accept="acceptFormats"
              />
              <v-file-input
                  hide-input
                  class="messages__files"
                  v-model="files[0]"
                  v-else
                  accept=".mp3, .m4a, .ogg, .png, .jpg, .jpeg"
              />
            </template>
          </v-textarea>
        </template>
        <v-list class="script-list">
          <v-list-item
              v-for="item in filteredScripts"
              :key="item.id"
              class="chat-script-window__item"
              @click="selectScriptText(item)"
          >
            <v-list-item-title class="script-group-name" :title="item.text">
              {{ item.script_group.name }} — <span class="linked">{{ item.name }}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-dialog
        v-model="changeUserChatModal"
        max-width="612px"
    >
      <ChangeChatUser :chatInfo="chatInfo" @close="changeUserChatModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="createReminderModal"
        max-width="612px"
    >
      <CreateReminderChat :chatInfo="chatInfo" @close="createReminderModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="groupMembersModal"
        max-width="612px"
    >
      <GroupMembers @close="groupMembersModal = false" :groupMembersModal="groupMembersModal"/>
    </v-dialog>
    <v-dialog
        v-model="fullImageModal"
        max-width="700px"
        content-class="zoom-image"
    >
      <img :src="selectedImage.url" @click="fullImageModal = false" class="zoom-out" width="100%" alt="Фото">
    </v-dialog>
    <v-dialog
        v-model="forwardMessagesModal"
        max-width="612px"
        attach=".chat"
    >
      <ForwardModal @close="forwardMessagesModal = false" :selectedMessages="selectedMessages" :currentChatId="chatInfo.id"/>
    </v-dialog>
    <ClientSidebar :showClientSidebar="showClientSidebar" v-if="chatInfo.client"
                   :client="chatInfo.client"/>
    <div class="chat-sidebar__toggle pointer" v-if="chatInfo.client"
         @click="showClientSidebar = !showClientSidebar"
         :class="{'toggle-active' : showClientSidebar}">
      <img width="26" :src="require('@/assets/img/chat/toggle.svg')" alt="">
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ChangeChatUser from "./ChangeChatUser";
import GroupMembers from "./GroupMembers";
import {pluralize, pluralizeWithoutCount} from "@/tools/Helpers";
import {OpenPagesMixin} from "@/mixins/OpenPagesMixin";
import ClientSidebar from "./ClientSidebar";
import {eventBus} from '../../main';
import {hasRight} from '@/utils/access/hasRight';
import EmojiPicker from "../EmojiPicker";
import downloadBase64File from '@/utils/files/downloadBase64File';
import CreateReminderChat from "./CreateReminderChat";
import MessageItem from "@/components/chat/MessageItem";
import ForwardModal from "@/components/chat/ForwardModal";
import {setChatText} from "@/tools/chatKeywords";

export default {
  name: "Messages",
  components: {ForwardModal, MessageItem, CreateReminderChat, EmojiPicker, ClientSidebar, GroupMembers, ChangeChatUser},
  mixins: [OpenPagesMixin],
  data: () => ({
    message: '',
    messageId: null,
    localMessages: [],
    createReminderModal: false,
    files: [],
    selectedUser: {},
    localForwardInfo: {},
    menu: false,
    showClientSidebar: false,
    changeUserChatModal: false,
    fullImageModal: false,
    groupMembersModal: false,
    selectedImage: {},
    page: 1,
    perPage: 50,
    loadingMessages: false,
    scrollWasOnBottom: true,
    // scripts: [],
    scriptTimerId: null,
    filesContent: [],
    progressMessage: false,
    selectedScript: null,
    editMessageMode: false,
    selectedMessages: [],
    forwardMessagesModal: false,
  }),
  computed: {
    ...mapState("chat", ["chatInfo", "messages"]),
    ...mapState("common", ["loading"]),
    ...mapState("user", ["profile"]),
    ...mapState("deals", ["deal"]),
    ...mapState("scripts", ["scripts"]),
    ...mapState('integrations', ['settings']),
    chatName() {
      if (this.chatInfo && this.chatInfo.users) {
        if (this.chatInfo.subtype === "group") {
          return this.chatInfo.name;
        } else {
          if (this.chatInfo.client) {
            return this.chatInfo.client.name;
          } else {
            let user = this.chatInfo.users.find(i => i.id !== this.profile.id);
            if (user) return user.name;
          }
        }
      }
      return '';
    },
    isOwnChat() {
      if (this.chatInfo.user)
        return this.chatInfo.user.id === this.profile.id || this.settings.integrations_users_view_all_chats;
      return true;
    },
    container() {
      return this.$el.querySelector("#messagesWindow");
    },
    scriptsShow() {
      return this.message && this.filteredScripts.length;
    },
    filteredScripts() {
      if (this.message.length > 1)
        return this.scripts.filter((i) => {
          return i.name.toLowerCase().includes(this.message.toLowerCase());
        });
      return [];
    },
    acceptFormats() {
      if (this.chatInfo.client.integration) {
        if (this.chatInfo.client.integration.type === 'vk') {
          return '.m4a, .png, .jpg, .jpeg, .doc, .docx, .pdf, .htm, .html, .txt, .rtf, .xml, .xlsx,.xls'
        }
      }
      return '.mp3, .m4a, .ogg, .png, .jpg, .jpeg, .doc, .docx, .pdf, .htm, .html, .txt, .rtf, .xml, .xlsx,.xls';
    }
  },
  methods: {
    ...mapActions({
      getUserChat: 'chat/getUserChat',
      getChatInfo: 'chat/getChatInfo',
      sendMessage: 'chat/sendMessage',
      editMessage: 'chat/editMessage',
      deleteMessage: 'chat/deleteMessage',
      pinChat: 'chat/pinChat',
      unpinChat: 'chat/unpinChat',
      getMessages: 'chat/getMessages',
      addMessages: 'chat/addMessages',
      setLocalChats: 'chat/setLocalChats',
      getScriptsSearch: 'scripts/getScriptsSearch',
      readChatMessages: 'chat/readChatMessages',
      removeChatInfo: 'chat/removeChatInfo',
      getChatAttachment: 'chat/getChatAttachment',
      getDeal: 'deals/getDealWithoutLoading',
      forwardMessages: 'chat/forwardMessages',
    }),
    pushEmoji(e) {
      this.message += (e);
      this.$refs.chatarea.focus();
    },
    hasRight: hasRight,
    returnGroupMembersCount(count) {
      let employees = ['сотрудник', 'сотрудника', 'сотрудников'];

      return pluralize(count, employees);
    },
    openFullImage(i) {
      this.selectedImage = i;
      this.fullImageModal = true;
    },
    setMessageText(item) {
      this.message = setChatText(item.text, this.chatInfo.client, this.deal);
    },
    selectScriptText(item) {
      this.selectedScript = item.id;

      //поля в быстрых фразах
      if (this.chatInfo.client) {
        this.setMessageText(item);

      } else {
        this.message = item.text;
      }

      if (item.attachments && item.attachments.length) {
        let files = [];
        item.attachments.map(i => {
          //если в быстрых фразах есть вложения IIFEc
          (async function () {
            const response = await fetch(i.url);
            // here image is url/location of image
            const blob = await response.blob();
            const file = new File([blob], i.name, {type: blob.type});
            files.push(file);
          })();
        })
        this.files = files;
      }
      if (this.$refs.chatarea) this.$refs.chatarea.focus();
    },
    handleScroll(e) {
      if (e.target.scrollTop <= this.container.scrollHeight / 4 && !this.loadingMessages) {
        let oldScrollHeight = this.container.scrollHeight;
        this.loadingMessages = true;
        this.page++;
        this.addMessages({
          chatId: this.$route.params.id,
          data: {
            page: this.page,
            per_page: this.perPage,
          },
        }).then((response) => {
          if (response.length) {
            e.target.scrollTop = this.container.scrollHeight - oldScrollHeight + oldScrollHeight / 4;
            this.loadingMessages = false;
          }
        });
      }
    },
    closeChat() {
      if (this.showClientSidebar) {
        this.showClientSidebar = true;
      } else {
        this.$router.push({path: `/chat/`}).catch(() => ({}));
      }
    },
    scrollToBottom() {
      if (this.scrollWasOnBottom)
        this.container.scrollTop = this.container.scrollHeight;
    },
    chatInit() {
      this.page = 1;
      this.editMessageMode = false;
      this.loadingMessages = false;
      eventBus.$emit('chatLoadingToggle', true);
      this.getMessages({
        chatId: this.$route.params.id,
        data: {
          page: this.page,
          per_page: this.perPage,
        },
      }).then((res) => {
        if (res) {
          this.localMessages = this.messages;
          this.$nextTick(() => {
            this.scrollToBottom();
          });
          eventBus.$emit('chatLoadingToggle', false);
        }
      });
      this.getChatInfo(this.$route.params.id).then(() => {
        if (this.chatInfo.client && this.chatInfo.client.last_deal) this.getDeal(this.chatInfo.client.last_deal.id);
      });
      this.message = '';
      this.selectedUser = this.profile.id;
    },
    downloadFile(args) {
      let {fileUrl, fileName} = args;
      let a = document.createElement("a");
      a.href = fileUrl;
      a.setAttribute("download", fileName);
      a.setAttribute("target", '_blank');
      a.click();
    },
    send(callback) {
      if (this.message || this.files.length || this.localForwardInfo.chat_messages) {
        this.progressMessage = true;
        this.scrollWasOnBottom = true;
        if (this.files.length === 0) {
          //если нет сообщения, но есть пересланные, то просто пересылаем
          if (this.localForwardInfo.chat_messages && !this.message) {
            this.localForwardMessages().finally(() => this.progressMessage = false);
            return;
          }
          // иначе
          callback({
            chatId: this.chatInfo.id,
            message: this.message,
            script_id: this.selectedScript,
            id: this.messageId,
          }).then(response => {
            if (response) {
              this.reloadChatMessages(response);
            }
            this.$nextTick(() => {
              if (this.localForwardInfo.chat_messages) {
                this.localForwardMessages();
              }
              this.scrollToBottom();
            });
          })
              .finally(() => this.progressMessage = false);
        } else {
          //Если с файлами
          let formData = new FormData();

          const audioTypes = ['audio/ogg', 'audio/mp3', 'audio/mpeg', 'audio/x-m4a'];
          const docsTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

          this.files.forEach((item) => {
            if (audioTypes.some(type => type === item.type)) {
              formData.append('audios[]', item);
            } else if (docsTypes.some(type => type === item.type)) {
              formData.append('docs[]', item);
            } else {
              formData.append('images[]', item);
            }
          });

          if (this.message) {
            formData.append('message', this.message);
          }

          if (this.messageId) {
            formData.append('id', this.messageId);
          }

          if (this.selectedScript) {
            formData.append('script_id', this.selectedScript);
          }

          formData.append('chatId', this.chatInfo.id);

          callback(formData).then((response) => {
            if (response) {
              this.reloadChatMessages(response);
            }
            this.$nextTick(() => {
              this.scrollToBottom();
              if (this.localForwardInfo.chat_messages) {
                this.localForwardMessages();
              }
            });
          })
            .finally(() => this.progressMessage = false);
        }
      }
    },
    removeChatMessage (id) {
      this.deleteMessage({chatId: this.chatInfo.id, id: id,}).then(() => {
        this.localMessages.map((el, i) => {
          if (el.id === id) {
            this.localMessages.splice(i, 1);
          }
        });
      });
    },
    localForwardMessages () {
      return this.forwardMessages({
        chat_id: this.localForwardInfo.chat_id,
        chat_messages: this.localForwardInfo.chat_messages,
        forward_chat_id: this.localForwardInfo.forward_chat_id,
      }).then(() => {
        this.scrollToBottom();
        this.clearForwardInfo();
      })
    },
    reloadChatMessages (response) {
      this.message = '';
      this.messageId = null;
      this.selectedScript = null;
      this.files = [];

      //Проверка редактируемое ли сообщение
      if (this.editMessageMode) {
        this.localMessages.map((el, i) => {
          if (el.id === response.data.response.id) {
            this.localMessages.splice(i, 1, response.data.response);
          }
        });
      } else {
        this.localMessages.push(...response.data.response);
      }

      //Проверка, является ли сообщение последним в чате, чтобы обновить его в меню диалогов
      const result = response.data.response;
      if (Array.isArray(result)) {
        this.setLocalChats(result[result.length - 1]);
      } else if (result && this.localMessages[this.localMessages.length - 1].id === result.id) {
        this.setLocalChats(result);
      }

      this.editMessageMode = false;
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    openCompanionPage() {
      if (this.chatInfo.subtype !== "group") {
        if (this.chatInfo.client) {
          this.$router.push({path: `/clients/${this.chatInfo.client.id}`});
        } else {
          let userId = this.chatInfo.users.find(i => i.id !== this.profile.id).id;
          this.$router.push({path: `/users/${userId}`});
        }
      }
    },
    pin(id) {
      this.pinChat(id).then((res) => {
        if (res) {
          this.menu = false;
        }
      });
    },
    unpin(id) {
      this.unpinChat(id).then((res) => {
        if (res) {
          this.menu = false;
        }
      });
    },
    escCheck(event) {
      if (event.key === 'Escape') {
        //if esc key was not pressed in combination with ctrl or alt or shift
        const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
        if (isNotCombinedKey) {
          this.closeChat();
        }
      }
    },
    openFileContent(args) {
      let {file} = args;
      this.getChatAttachment({
        chat_id: this.chatInfo.id,
        attachment_id: file.id,
      }).then((response) => {
        if (file.type === "video") {
          //скачиваем
          downloadBase64File(file.mime_type, response, file.file_name);
        } else {
          downloadBase64File('image/jpeg', response, 'Фотография');
        }
      });
    },
    filesContentHasFile(fileId) {
      return this.filesContent.find(f => f.id === fileId);
    },
    editMessageLocal (message) {
      let files = [];
      message.attachments.map(i => {
        //если в быстрых фразах есть вложения IIFEc
        (async function () {
          const response = await fetch(i.url);
          // here image is url/location of image
          const blob = await response.blob();
          const file = new File([blob], i.name, {type: blob.type});
          files.push(file);
        })();
      })

      this.editMessageMode = true;
      this.$refs.chatarea.focus();
      this.message = message.message;
      this.messageId = message.id || message.chat_message_id;
      this.files = files;
    },
    selectMessage (message) {
      this.selectedMessages.push(message);
    },
    deselectMessage (message) {
      this.selectedMessages.map((item, index) => {
        if (item.id === message.id) {
          this.selectedMessages.splice(index, 1);
        }
      });
    },
    stopEditMode () {
      this.editMessageMode = false;
      this.message = '';
      this.files = [];
    },
    clearForwardInfo () {
      this.localForwardInfo = {};
    },
    returnForwardMessagesCount(messages) {
      let items = ['пересланное сообщение', 'пересланных сообщения', 'пересланных сообщений'];

      return pluralizeWithoutCount(messages, items);
    },
  },
  mounted() {
    this.$echo.private(`chat.${this.profile.id}`).listen('ChatMessage', (payload) => {
      if (payload.chat_id === this.chatInfo.id) {

        this.scrollWasOnBottom = this.container.scrollHeight - this.container.scrollTop <= this.container.clientHeight + 50;

        this.localMessages.push(payload);
        this.readChatMessages();

        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    });
    eventBus.$on('showClientSidebarEvent', (payload) => {
      this.showClientSidebar = payload;
    });
    eventBus.$on('selectScriptFromSide', (payload) => {
      this.selectScriptText(payload);
    });
    eventBus.$on('sendForwardMessages', (payload) => {
      this.localForwardInfo = payload;
    });

    document.addEventListener('keydown', this.escCheck);
  },
  beforeDestroy() {
    this.removeChatInfo();
    document.removeEventListener('keydown', this.escCheck);
  },
  watch: {
    '$route.path': {
      handler: function () {
        this.chatInit();
      },
      deep: true,
      immediate: true
    },
    message(val) {
      if (!val) {
        this.selectedScript = null;
      }
    },
    chatInfo(val) {
      if (val.unread_messages_count) this.readChatMessages();
      this.selectedMessages = [];
      this.$refs.chatarea.focus();
    },
    groupMembersModal(val) {
      if (!val) {
        this.getChatInfo(this.chatInfo.id);
      }
    },
    forwardMessagesModal (val) {
      if (!val) {
        this.selectedMessages = [];
      }
    }
  },

}
</script>

<style lang="scss">
.companion-name {
  white-space: nowrap;
}

.integration-name {
  max-width: 100%;
  display: block;
}

.integration-name {
  display: block;
}

.messages {
  /*max-width: 581px;*/
  //min-width: 581px;
  height: 100%;
  position: relative;
  //border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;

  &__edit-mode {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 30px 10px 60px;
    top: -30px;
    background: #fff;
    font-size: 13px;
    font-weight: 600;
    color: #008A80;
  }

  &__attachment {
    &-story {
      font-size: 14px;
      color: #2196F3;
    }

    &-file {
      font-size: 14px;
      color: $evi-headline-color;
    }

    &-video {
      max-height: 400px;
      max-width: 100%;
    }
  }

  &__user-name {
    color: $evi-main-color;
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  &__item {
    display: flex;
    margin-bottom: 15px;
    align-items: flex-end;
    position: relative;

    &.right-message {
      .messages__body {
        background: #CBD6FF !important;
      }
    }

    &.selected {
      .messages__body {
        opacity: 0.6;
      }
    }

    &.int-message {
      .messages__body {
        background: #AEC0FF !important;
      }
    }

  }

  &__body {
    background: #FFFFFF;
    border-radius: 12px 12px 12px 0;
    padding: 7px 13px 3px;
    display: flex;
    flex-direction: column;
    position: relative;

    .text {
      //max-width: 70%;
      font-weight: normal;
      font-size: 13px;
      color: $evi-headline-color;
      margin-right: 10px;
      word-break: break-word;
      line-height: 1.29;
    }

    .date {
      //max-width: 30%;
      font-size: 11px;
      color: $evi-grey-color;
      align-self: flex-end;
      min-width: 112px;
      text-align: right;
    }
  }

  &__header {
    padding: 6px 17px;
    display: flex;
    position: relative;
    min-height: 58px;
    max-height: 58px;
  }

  &__dots {
    position: absolute;
    cursor: pointer;
    right: 24px;
    top: 15px;
  }

  &__integration {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: hidden;

    img {
      margin-right: 5px;
      margin-left: 5px;
      align-items: center;
    }
  }

  &__companion {
    cursor: pointer;
    max-width: calc(100% - 100px);
    line-height: 21px;

    .name {
      font-weight: 600;
      font-size: 13px;
      color: $evi-headline-color;
    }

    small {
      font-weight: normal;
      font-size: 12px;
      color: $evi-grey-color;
    }

    .group-info {
      font-weight: normal;
      font-size: 13px;
      text-decoration-line: underline;
      color: $evi-headline-color;
    }
  }

  &__window {
    //$evi-chat-window-height
    width: 100%;
    height: $evi-chat-window-height;
    overflow-y: auto;
    padding: 23px;
    background-repeat: repeat;
  }

  &__text {
    width: 100%;
    padding: 0 24px;
    position: relative;

    .v-menu__content {
      bottom: 0;
      top: auto !important;
      box-shadow: none !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  &__file-attachment {
    border-radius: 3px;
    margin-right: 12px;
    margin-bottom: 7px;
    padding: 10px 45px 10px 12px;
    max-height: 40px;
    height: 40px;
    text-decoration: none;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    color: #808386;
    position: relative;
    cursor: pointer;
    background: #F0F0F0;

    .close {
      position: absolute;
      right: 12px;
      top: 10px;
    }
  }
}

.chat-script-window {
  &__item {
    .script-group-name {
      font-size: 13px;
    }
    .linked {
      color: #289E80;
      border-bottom: 1px solid;
      cursor: pointer;
      font-size: 13px;
    }
  }
}

.message-not-supported,
.theme--dark .chat .messages__body .message-not-supported {
  font-style: italic;
  color: grey !important;
}

.progress-message {
  position: absolute;
  width: 100%;
  left: 0;
  top: 52px;
  z-index: 1;
}

.zoom-image {
  max-height: unset !important;
}
</style>
