<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Напоминание</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')" />
        </div>
        <v-col
            cols="12"
        >
          <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateFormated"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  label="Дата напоминания"
                  color="#44D370"
                  class="evi-text-field"
                  :rules="rules"
              >
                <template v-slot:label>
                  Дата
                  <span class="important">*</span>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                @input="menu = false"
                color="#44D370"
                first-day-of-week="1"
            ></v-date-picker>
          </v-menu>
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="description"
              label="Текст"
              maxLength="50"
              counter="50"
          >
          </v-text-field>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          Создать
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ChangeChatUser",
  props: {
    chatInfo: {
      type: Object,
    }
  },
  data: () => ({
    valid: true,
    menu: false,
    date: '',
    description: '',
    rules: [(v) => !!v || 'Выберите дату'],
  }),
  computed: {
    dateFormated() {
      return this.$moment(this.date).format('DD.MM.YYYY');
    },
  },
  methods: {
    ...mapActions({
      createReminders: 'chat/createReminders',
      addReminder: 'chat/addReminder',
    }),
    validate () {
      return !!this.$refs.form.validate();
    },
    create () {
      if (this.validate()) {
        let payload = {
          client_id: this.chatInfo.client.id,
          date: this.date,
        };

        if (this.description) {
          payload.description = this.description;
        }

        this.createReminders(payload).then((res) => {
          let today = new Date();
          let tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));

          if (this.$moment(today).format('YYYY-MM-DD') === this.$moment(this.date).format('YYYY-MM-DD')) {
            this.addReminder({
              name: 'today',
              data: res.response,
            });
          } else if (this.$moment(tomorrow).format('YYYY-MM-DD') === this.$moment(this.date).format('YYYY-MM-DD')) {
            this.addReminder({
              name: 'tomorrow',
              data: res.response,
            });
          } else if (this.$moment(this.date).format('YYYY-MM-DD').valueOf() > this.$moment(today).format('YYYY-MM-DD').valueOf()) {
            this.addReminder({
              name: 'future',
              data: res.response,
            });
          } else {
            this.addReminder({
              name: 'outdated',
              data: res.response,
            });
          }

          if (res) {
            this.closeDialog();
          }
        });
      }
    },
    closeDialog () {
      this.$emit('close');
      this.date = this.$moment(new Date()).format('YYYY-MM-DD');
      this.description = '';
      this.$refs.form.resetValidation();
    }
  },
  mounted() {
    this.date = this.$moment(new Date()).format('YYYY-MM-DD');
  },
}
</script>

<style lang="scss" scoped>
</style>
