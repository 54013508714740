<template>
  <v-card class="modal-create forward-modal">
    <v-form ref="form">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center">Переслать сообщения</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')" />
        </div>
        <div
            class="justify-center"
        >
          <v-tabs
              v-model="tab"
              align-with-title
              class="chat-all-dialogs__tabs"
              :show-arrows="false"
          >
            <v-tab
                v-for="item in tabs"
                :key="item"
            >
              {{ item }}
            </v-tab>
            <div class="d-flex">
              <v-tab class="mr-2">
                <img v-if="isPinnedChat" :src="require('@/assets/img/chat/pinned_active.svg')" width="23" alt="">
                <img v-else :src="require('@/assets/img/chat/pinned.svg')" width="23" alt="">
              </v-tab>
            </div>
          </v-tabs>
          <v-text-field
              class="evi-text-field mt-5 mb-5"
              color="#44D370"
              placeholder="Поиск"
              v-model="search"
          >
            <template v-slot:prepend-inner>
              <img :src="require('@/assets/img/chat/search.svg')" alt="">
            </template>
          </v-text-field>
          <div class="forward-modal__chats" @scroll="handleScroll">
            <div class="forward-modal__chat" v-for="i in chatsForward" :key="i.id" @click="toChat(i)">
              <v-avatar
                  color="#FFFFFF"
                  size="47"
                  class="mr-2"
              >
                <img
                    :src="chatImage(i) || require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
              <div class="forward-modal__chat-name">
                {{ chatName(i) }}
                <img class="ml-1 int-type" width="20"
                     v-if="i.client && i.client.integration"
                     :src="require(`@/assets/img/integrations/${i.client.integration.type}.svg`)"
                     alt=""/>
                <img class="ml-1 int-type" width="20"
                     v-else-if="i.client && i.client.integration_type"
                     :src="require(`@/assets/img/integrations/${i.client.integration_type}.svg`)"
                     alt=""/>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {hasRight} from "@/utils/access/hasRight";
import {eventBus} from "@/main";

export default {
  name: "ForwardModal",
  props: {
    selectedMessages: {
      type: Array,
      default: () => [],
    },
    currentChatId: {
      type: Number,
      default: 0,
    }
  },
  data: () => ({
    tab: 0,
    tabs: ['Клиенты', 'Сотрудники'],
    chatsLoading: false,
    search: '',
    newChats: [],
    timerId: null,
    page: 1,
    perPage: 30,
  }),
  computed: {
    ...mapState("chat", ["chatsForward",]),
    ...mapState("common", ["loading",]),
    ...mapState("user", ["profile"]),
    isPinnedChat() {
      return this.tab === 3;
    },
  },
  methods: {
    ...mapActions({
      forwardMessages: 'chat/forwardMessages',
      getChats: 'chat/getChatsForward',
      setChatType: 'chat/setChatType',
      addChats: 'chat/addChatsForward',
      getClientChat: 'chat/getClientChat',
      getUserChat: 'chat/getUserChat',
    }),
    hasRight: hasRight,
    getChatsByTab(val) {
      this.chatsLoading = false;
      let args = {
        page: 1,
        per_page: this.perPage,
      };

      if (this.tabs[val] === "Клиенты") {
        if (!this.hasRight('chat.accessAll') && !this.hasRight('chat.accessOwn')) {
          args.type = null;
        } else {
          args.type = 'outer';
        }
      } else if (this.tabs[val] === "Сотрудники") {
        args.type = 'inner';
      } else {
        args.type = 'pinned';
      }
      this.setChatType(args.type);

      if (this.search) {
        if (this.tabs[val] === "Клиенты")
          args['filter[client.name]'] = this.search;
        else if (this.tabs[val] === "Сотрудники")
          args['filter[chat]'] = this.search;
        else
          args['filter[chat]'] = this.search;
      }

      this.getChats(args);
    },
    addChatsByTab(val) {
      let args = {
        page: this.page,
        per_page: this.perPage,
      };

      if (this.tabs[val] === "Клиенты") {
        args.type = 'outer';
      } else if (this.tabs[val] === "Сотрудники") {
        args.type = 'inner';
      } else {
        args.type = 'pinned';
      }

      if (this.search) {
        args['filter[name]'] = this.search;
      }

      this.addChats(args).then((res) => {
        this.newChats = res;
        if (res.length) {
          this.chatsLoading = false;
        }
      });
    },
    closeDialog () {
      this.$emit('close');
    },
    chatName(chat) {
      if (chat.subtype === "group") {
        //беседа
        return chat.name;
      } else {
        if (chat.client) {
          return chat.client.name;
        } else if (chat.chat && chat.chat.client) {
          return chat.chat.client.name;
        } else if (chat.users) {
          let user = chat.users.find(i => i.id !== this.profile.id);
          if (user) return user.name;
        }
      }
    },
    chatImage(i) {
      if (i.subtype === "group") {
        return require('@/assets/img/chat/group.png');
      } else {
        if (i.client) {
          return i.client.avatar_url;
        } else if (i.chat && i.chat.client) {
          return i.chat.client.avatar_url;
        } else if (i.users) {
          let user = i.users.find(i => i.id !== this.profile.id);
          if (user) return user.avatar_url;
        }
      }
    },
    handleScroll(e) {
      if (e.target.scrollTop >= (e.target.scrollHeight / 2 - e.target.clientHeight) && !this.chatsLoading) {
        this.chatsLoading = true;
        let oldScrollPos = e.target.scrollTop;
        this.updatePages();
        this.$nextTick(() => {
          e.target.scrollTop = oldScrollPos;
        });
      }
    },
    updatePages() {
      this.page++;
      this.addChatsByTab(this.tab);
    },
    forward (chatId) {
      this.forwardMessages({
        chat_id: this.currentChatId,
        chat_messages: this.selectedMessages.map(i => i.id),
        forward_chat_id: chatId,
      })
    },
    toChat(chat) {
      if (chat.type === 'outer') {
        this.$router.push({path: `/chat/clients/${chat.id}`}).catch(() => {});
      } else if (chat.type === 'inner') {
        this.$router.push({path: `/chat/users/${chat.id}`}).catch(() => {});
      }
      eventBus.$emit('sendForwardMessages', {
        chat_id: this.currentChatId,
        chat_messages: this.selectedMessages.map(i => i.id),
        forward_chat_id: chat.id,
      });
      this.closeDialog();
    },
  },
  mounted() {
    this.getChatsByTab(this.tab);
  },
  watch: {
    tab(val) {
      this.page = 1;
      this.getChatsByTab(val);
    },
    search() {
      if (this.timerId)
        clearTimeout(this.timerId);

      this.page = 1;
      this.timerId = setTimeout(() => {
        this.getChatsByTab(this.tab);
      }, 500);
    },
  }
}
</script>

<style lang="scss">
.forward-modal {
  .chat-all-dialogs__tabs {
    .v-slide-group__content {
      justify-content: center;
    }
  }
  &__chats {
    overflow-y: auto;
    max-height: 400px;
  }
  &__chat {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
  }
  &__chat-name {
    font-weight: 600;
    font-size: 13px;
    color: #111;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .int-type {
      position: absolute;
      left: -5px;
      bottom: 27px;
    }
  }
}
</style>
