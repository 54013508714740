var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"modal-create"},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('span',{staticClass:"text-h5 text-center mb-11"},[_vm._v("Добавление сделки")])]),_c('v-card-text',{staticClass:"modal-create__form"},[_c('div',{staticClass:"close-dialog",on:{"click":_vm.closeDialog}},[_c('img',{attrs:{"src":require('@/assets/img/common/close-dialog.svg')}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"evi-text-field mb-5",attrs:{"color":"#44D370","hint":"Если не заполнять поле, присвоится номер","persistent-hint":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Название сделки (номер) ")]},proxy:true}]),model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-autocomplete',{staticClass:"evi-autocomplete mb-5",attrs:{"color":"#44D370","items":_vm.localClients,"item-text":"name","item-value":"id","rules":_vm.clientRules,"loading":_vm.clientsLoading,"search-input":_vm.clientSearch,"clearable":"","item-color":"green"},on:{"focus":_vm.loadClients,"update:searchInput":function($event){_vm.clientSearch=$event},"update:search-input":function($event){_vm.clientSearch=$event}},nativeOn:{"input":function($event){return _vm.searchClient(_vm.clientSearch)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Клиент "),_c('span',{staticClass:"important"},[_vm._v("*")])]},proxy:true},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.clientIntersect),expression:"clientIntersect"}]})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"user-page__avatar mr-3",attrs:{"color":"#FFFFFF","size":"30"}},[_c('img',{attrs:{"src":item.avatar_url ||require('@/assets/img/users/profile-avatar.png'),"alt":""}})]),_c('div',{staticClass:"v-list-item__title"},[_vm._v(_vm._s(item.name))])]}},{key:"append",fn:function(){return [_c('span',{staticClass:"evi-autocomplete__arrow"},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]},proxy:true}]),model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}}),_c('v-autocomplete',{staticClass:"evi-autocomplete mb-5",attrs:{"color":"#44D370","items":_vm.localUsers,"item-text":"name","item-value":"id","rules":_vm.executorRules,"loading":_vm.userLoading,"search-input":_vm.userSearch,"clearable":"","item-color":"green"},on:{"focus":_vm.loadUsers,"update:searchInput":function($event){_vm.userSearch=$event},"update:search-input":function($event){_vm.userSearch=$event}},nativeOn:{"input":function($event){return _vm.searchUser(_vm.userSearch)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Исполнитель "),_c('span',{staticClass:"important"},[_vm._v("*")])]},proxy:true},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.userIntersect),expression:"userIntersect"}]})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"user-page__avatar mr-3",attrs:{"color":"#FFFFFF","size":"30"}},[_c('img',{attrs:{"src":item.avatar_url ||require('@/assets/img/users/profile-avatar.png'),"alt":""}})]),_c('div',{staticClass:"v-list-item__title"},[_vm._v(_vm._s(item.name))])]}},{key:"append",fn:function(){return [_c('span',{staticClass:"evi-autocomplete__arrow"},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]},proxy:true}]),model:{value:(_vm.executor),callback:function ($$v) {_vm.executor=$$v},expression:"executor"}}),_c('v-autocomplete',{staticClass:"evi-autocomplete mb-5",attrs:{"color":"#44D370","items":_vm.statuses,"item-text":"name","item-value":"id","rules":_vm.statusRules,"clearable":"","item-color":"green"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Статус "),_c('span',{staticClass:"important"},[_vm._v("*")])]},proxy:true},{key:"append",fn:function(){return [_c('span',{staticClass:"evi-autocomplete__arrow"},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"modal-create__color-select"},[_c('span',{staticClass:"color",style:({backgroundColor: ("" + (item.color))})}),_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"modal-create__color-select"},[_c('span',{staticClass:"color mt-1",style:({backgroundColor: ("" + (item.color))})}),_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('v-card-actions',{staticClass:"modal-create__actions"},[_c('v-btn',{staticClass:"evi-button-green evi-button-green--fill",on:{"click":_vm.create}},[_vm._v(" Добавить сделку ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }