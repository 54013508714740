const moment = require('moment');

export function setChatText(text, client = {}, deal = {}) {
    let localText = text;
    let name = client.name.split(' ')[0];
    let delivery = deal?.delivery;
    let cdek = delivery?.cdek;

    const moneyMask = (value) => {
        value = value ? value.replace('.', '').replace(',', '').replace(/\D/g, '') : 0;

        const options = {minimumFractionDigits: 2};
        const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(value) / 100
        )

        return result.replace('.', ' ');
    }

    //Поля клиента
    if (localText.includes('[name]') || localText.includes('name')) {
        let re = "name";

        localText = localText.replace(new RegExp(re, "g"), name).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Клиент.Имя]') || localText.includes('Клиент.Имя')) {
        let re = "Клиент.Имя";

        localText = localText.replace(new RegExp(re, "g"), name).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Имя]') || localText.includes('Имя')) {
        let re = "Имя";

        localText = localText.replace(new RegExp(re, "g"), name).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Клиент.Дата рождения]') || localText.includes('Клиент.Дата рождения')) {
        let re = "Клиент.Дата рождения";

        localText = localText.replace(new RegExp(re, "g"), client.birth).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Клиент.Email]') || localText.includes('Клиент.Email')) {
        let re = "Клиент.Email";

        localText = localText.replace(new RegExp(re, "g"), client.email).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Клиент.Город]') || localText.includes('Клиент.Город')) {
        let re = "Клиент.Город";

        localText = localText.replace(new RegExp(re, "g"), client.city).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Клиент.Адрес]') || localText.includes('Клиент.Адрес')) {
        let re = "Клиент.Адрес";

        localText = localText.replace(new RegExp(re, "g"), client.address).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Клиент.Телефон]') || localText.includes('Клиент.Телефон')) {
        let re = "Клиент.Телефон";

        localText = localText.replace(new RegExp(re, "g"), client.phone).replace(/[[\]']+/g, '');
    }
    if (client.custom_fields && client.custom_fields.length) {
        // для кастомных полей клиента

        client.custom_fields.forEach(i => {

            if (localText.includes(`Клиент.${i.name}`)) {

                let re = `Клиент.${i.name}`;
                let value = i.value;

                if (i.type === 'date') {
                    value = moment(i.value).format('DD.MM.YYYY');
                } else if (i.type === 'datetime') {
                    value = moment(i.value).format('DD.MM.YYYY HH:mm');
                }

                localText = localText.replace(new RegExp(re, "g"), value).replace(/[[\]']+/g, '');
            }
        });
    }

    //Поля сделки
    if (localText.includes('[Сделка.Номер]') || localText.includes('Сделка.Номер')) {
        let re = "Сделка.Номер";

        localText = localText.replace(new RegExp(re, "g"), deal.name).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Сделка.Скидка]') || localText.includes('Сделка.Скидка')) {
        let re = "Сделка.Скидка";

        localText = localText.replace(new RegExp(re, "g"), `${deal.discount}%`).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Сделка.Исполнитель]') || localText.includes('Сделка.Исполнитель')) {
        let re = "Сделка.Исполнитель";

        localText = localText.replace(new RegExp(re, "g"), deal.executor.name).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Сделка.Предоплата]') || localText.includes('Сделка.Предоплата')) {
        let re = "Сделка.Предоплата";

        localText = localText.replace(new RegExp(re, "g"), moneyMask(deal.prepayment)).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Сделка.Стоимость доставки]') || localText.includes('Сделка.Стоимость доставки')) {
        let re = "Сделка.Стоимость доставки";

        localText = localText.replace(new RegExp(re, "g"), moneyMask(deal.delivery_cost)).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Сделка.При получении]') || localText.includes('Сделка.При получении')) {
        let re = "Сделка.При получении";

        localText = localText.replace(new RegExp(re, "g"), moneyMask(deal.payment_upon_receipt)).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Сделка.Итоговая сумма]') || localText.includes('Сделка.Итоговая сумма')) {
        let re = "Сделка.Итоговая сумма";

        localText = localText.replace(new RegExp(re, "g"), moneyMask(deal.total_sum)).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Сделка.Сумма корзины]') || localText.includes('Сделка.Сумма корзины')) {
        let re = "Сделка.Сумма корзины";

        localText = localText.replace(new RegExp(re, "g"), moneyMask(deal.sum)).replace(/[[\]']+/g, '');
    }

    if (localText.includes('[Сделка.Корзина]') || localText.includes('Сделка.Корзина')) {
        let re = "Сделка.Корзина";

        let products = '';

        for (let item of deal.products) {
            const summary = parseFloat(item.deal_price * item.count).toFixed(2);
            products += '- ' + item.name + ' — ' + `${moneyMask(item.deal_price)} x ${item.count} = ${moneyMask(summary.toString())}` + '\n'
        }

        localText = localText.replace(new RegExp(re, "g"), products).replace(/[[\]']+/g, '');
    }

    if (deal.custom_fields && deal.custom_fields.length) {
        // для кастомных полей клиента

        deal.custom_fields.forEach(i => {

            if (localText.includes(`Сделка.${i.name}`)) {

                let re = `Сделка.${i.name}`;
                let value = i.value;

                if (i.type === 'date') {
                    value = moment(i.value).format('DD.MM.YYYY');
                } else if (i.type === 'datetime') {
                    value = moment(i.value).format('DD.MM.YYYY HH:mm');
                }

                localText = localText.replace(new RegExp(re, "g"), value).replace(/[[\]']+/g, '');
            }
        });
    }

    //Поля доставки
    if (localText.includes('[Доставка.Служба доставки]') || localText.includes('Доставка.Служба доставки')) {
        let re = "Доставка.Служба доставки";
        let deliveryType = '';
        if (delivery?.type === 'own') deliveryType = 'Своими силами'
        if (delivery?.type === 'cdek') deliveryType = 'СДЭК'

        localText = localText.replace(new RegExp(re, "g"), deliveryType).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Доставка.Способ доставки]') || localText.includes('Доставка.Способ доставки')) {
        let re = "Доставка.Способ доставки";
        let deliveryMethod = '';
        if (delivery?.method === 'courier') {
            deliveryMethod = 'Курьер';
        } else if (delivery?.method === 'pickup') {
            deliveryMethod = 'Самовывоз';
        } else if (delivery?.method === 'post') {
            deliveryMethod = 'Почта';
        } else if (delivery?.method === 'other') {
            deliveryMethod = 'Другое';
        }

        localText = localText.replace(new RegExp(re, "g"), deliveryMethod).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Доставка.ФИО]') || localText.includes('Доставка.ФИО')) {
        let re = "Доставка.ФИО";

        localText = localText.replace(new RegExp(re, "g"), delivery?.full_name).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Доставка.Номер телефона]') || localText.includes('Доставка.Номер телефона')) {
        let re = "Доставка.Номер телефона";

        localText = localText.replace(new RegExp(re, "g"), delivery?.mobile_phone).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Доставка.Страна]') || localText.includes('Доставка.Страна')) {
        let re = "Доставка.Страна";

        localText = localText.replace(new RegExp(re, "g"), delivery?.country).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Доставка.Город]') || localText.includes('Доставка.Город')) {
        let re = "Доставка.Город";

        localText = localText.replace(new RegExp(re, "g"), delivery?.city).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Доставка.Адрес]') || localText.includes('Доставка.Адрес')) {
        let re = "Доставка.Адрес";

        localText = localText.replace(new RegExp(re, "g"), delivery?.address).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Доставка.Индекс]') || localText.includes('Доставка.Индекс')) {
        let re = "Доставка.Индекс";

        localText = localText.replace(new RegExp(re, "g"), delivery?.postal_code).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Доставка.Дата отправки]') || localText.includes('Доставка.Дата отправки')) {
        let re = "Доставка.Дата отправки";

        localText = localText.replace(new RegExp(re, "g"), moment(delivery?.send_date).format('DD.MM.YYYY')).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Доставка.Дата доставки]') || localText.includes('Доставка.Дата доставки')) {
        let re = "Доставка.Дата доставки";

        localText = localText.replace(new RegExp(re, "g"), moment(delivery?.pickup_date).format('DD.MM.YYYY')).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Доставка.Время доставки с]') || localText.includes('Доставка.Время доставки с')) {
        let re = "Доставка.Время доставки с";

        localText = localText.replace(new RegExp(re, "g"), delivery?.pickup_time_from).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Доставка.Время доставки по]') || localText.includes('Доставка.Время доставки по')) {
        let re = "Доставка.Время доставки по";

        localText = localText.replace(new RegExp(re, "g"), delivery?.pickup_time_to).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Доставка.Статус доставки]') || localText.includes('Доставка.Статус доставки')) {
        let re = "Доставка.Статус доставки";

        localText = localText.replace(new RegExp(re, "g"), delivery?.postal_status).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Доставка.Примечание к доставке]') || localText.includes('Доставка.Примечание к доставке')) {
        let re = "Доставка.Примечание к доставке";

        localText = localText.replace(new RegExp(re, "g"), delivery?.internal_comment).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Доставка.Примечание от клиента]') || localText.includes('Доставка.Примечание от клиента')) {
        let re = "Доставка.Примечание от клиента";

        localText = localText.replace(new RegExp(re, "g"), delivery?.comment).replace(/[[\]']+/g, '');
    }
    if (localText.includes('[Доставка.Трек номер]') || localText.includes('Доставка.Трек номер')) {
        let re = "Доставка.Трек номер";

        localText = localText.replace(new RegExp(re, "g"), delivery?.tracking_number).replace(/[[\]']+/g, '');
    }

    //Поля СДЭКА
    if (localText.includes('[СДЭК.Тариф]') || localText.includes('СДЭК.Тариф')) {
        let re = "СДЭК.Тариф";

        localText = localText.replace(new RegExp(re, "g"), cdek.cdek_tariff ? cdek.cdek_tariff.name : 'Нет данных').replace(/[[\]']+/g, '');
    }
    if (localText.includes('[СДЭК.ФИО получателя]') || localText.includes('СДЭК.ФИО получателя')) {
        let re = "СДЭК.ФИО получателя";

        localText = localText.replace(new RegExp(re, "g"), cdek.cdek_recipient_name ? cdek.cdek_recipient_name : 'Нет данных').replace(/[[\]']+/g, '');
    }
    if (localText.includes('[СДЭК.Номер телефона получателя]') || localText.includes('СДЭК.Номер телефона получателя')) {
        let re = "СДЭК.Номер телефона получателя";

        localText = localText.replace(new RegExp(re, "g"), cdek.cdek_recipient_phone ? cdek.cdek_recipient_phone : 'Нет данных').replace(/[[\]']+/g, '');
    }
    if (localText.includes('[СДЭК.Название компании]') || localText.includes('СДЭК.Название компании')) {
        let re = "СДЭК.Название компании";

        localText = localText.replace(new RegExp(re, "g"), cdek.cdek_recipient_company ? cdek.cdek_recipient_company : 'Нет данных').replace(/[[\]']+/g, '');
    }
    if (localText.includes('[СДЭК.Вес посылки]') || localText.includes('СДЭК.Вес посылки')) {
        let re = "СДЭК.Вес посылки";

        localText = localText.replace(new RegExp(re, "g"), cdek.cdek_package_weight ? cdek.cdek_package_weight : 'Нет данных').replace(/[[\]']+/g, '');
    }
    if (localText.includes('[СДЭК.Длина посылки]') || localText.includes('СДЭК.Длина посылки')) {
        let re = "СДЭК.Длина посылки";

        localText = localText.replace(new RegExp(re, "g"), cdek.cdek_package_length ? cdek.cdek_package_length : 'Нет данных').replace(/[[\]']+/g, '');
    }
    if (localText.includes('[СДЭК.Ширина посылки]') || localText.includes('СДЭК.Ширина посылки')) {
        let re = "СДЭК.Ширина посылки";

        localText = localText.replace(new RegExp(re, "g"), cdek.cdek_package_width ? cdek.cdek_package_width : 'Нет данных').replace(/[[\]']+/g, '');
    }
    if (localText.includes('[СДЭК.Высота посылки]') || localText.includes('СДЭК.Высота посылки')) {
        let re = "СДЭК.Высота посылки";

        localText = localText.replace(new RegExp(re, "g"), cdek.cdek_package_height ? cdek.cdek_package_height : 'Нет данных').replace(/[[\]']+/g, '');
    }
    if (localText.includes('[СДЭК.Откуда]') || localText.includes('СДЭК.Откуда')) {
        let re = "СДЭК.Откуда";

        localText = localText.replace(new RegExp(re, "g"), cdek.cdek_from_city ? `${cdek.cdek_from_city.city}, ${cdek.cdek_from_city.sub_region}, ${cdek.cdek_from_city.region}` : 'Нет данных').replace(/[[\]']+/g, '');
    }
    if (localText.includes('[СДЭК.Куда]') || localText.includes('СДЭК.Куда')) {
        let re = "СДЭК.Куда";

        localText = localText.replace(new RegExp(re, "g"), cdek.cdek_to_city ? `${cdek.cdek_to_city.city}, ${cdek.cdek_to_city.sub_region}, ${cdek.cdek_to_city.region}` : 'Нет данных').replace(/[[\]']+/g, '');
    }
    if (localText.includes('[СДЭК.Из ПВЗ]') || localText.includes('СДЭК.Из ПВЗ')) {
        let re = "СДЭК.Из ПВЗ";

        localText = localText.replace(new RegExp(re, "g"), cdek.cdek_from_office ? cdek.cdek_from_office.name : 'Нет данных').replace(/[[\]']+/g, '');
    }
    if (localText.includes('[СДЭК.В ПВЗ]') || localText.includes('СДЭК.В ПВЗ')) {
        let re = "СДЭК.В ПВЗ";

        localText = localText.replace(new RegExp(re, "g"), cdek.cdek_to_office ? cdek.cdek_to_office.name : 'Нет данных').replace(/[[\]']+/g, '');
    }
    if (localText.includes('[СДЭК.С адреса]') || localText.includes('СДЭК.С адреса')) {
        let re = "СДЭК.С адреса";

        localText = localText.replace(new RegExp(re, "g"), cdek.cdek_from_address ? cdek.cdek_from_address : 'Нет данных').replace(/[[\]']+/g, '');
    }
    if (localText.includes('[СДЭК.На адрес]') || localText.includes('СДЭК.На адрес')) {
        let re = "СДЭК.На адрес";

        localText = localText.replace(new RegExp(re, "g"), cdek.cdek_to_address ? cdek.cdek_to_address : 'Нет данных').replace(/[[\]']+/g, '');
    }
    if (localText.includes('[СДЭК.Трек номер]') || localText.includes('СДЭК.Трек номер')) {
        let re = "СДЭК.Трек номер";

        localText = localText.replace(new RegExp(re, "g"), cdek.cdek_tracking_number ? cdek.cdek_tracking_number : 'Нет данных').replace(/[[\]']+/g, '');
    }
    return localText;
}
