<template>
  <v-hover
      v-slot="{ hover }"
      class="message-wrapper"
  >
    <div class="messages__item" :key="data.id"
         @click="selectMessageLocal"
         :class="{'right-message' : isOwnMessage(data) || notClientMessage,
          'mb-1' : !showAvatar(data, localMessages[index +1]),
           'int-message': isIntMessage(data),
            'hovered' : hover,
            'pointer' : selectedMessageMode,
             'selected' : selectedMessages.includes(data)}">
      <v-avatar
          color="#FFFFFF"
          size="40"
          class="mr-2 avatar pointer"
          v-if="showAvatar(data, localMessages[index +1])"
          @click="openMemberPage(data)"
      >
        <img
            :src="chatImage(data) || require('@/assets/img/users/profile-avatar.png')"
            alt=""
        >
      </v-avatar>
      <div class="messages__body" :class="{'ml-12' : !showAvatar(data, localMessages[index +1])}">
        <div v-if="showMessageUserName(data, localMessages[index -1])" class="messages__user-name">
          {{ messageUserName(data) }}
        </div>
        <div v-if="data.is_forwarded" class="messages__forwarded">
          Переслано от <strong>{{showForwardMessageName(data.forwarded_message)}}</strong>
        </div>
        <div class="d-flex flex-column" v-if="data.message || (data.attachments && data.attachments.length)">
          <div v-html="messageText"></div>
          <div v-if="data.attachments && data.attachments.length" class="mt-2">
            <div class="messages__attachment" v-for="a in data.attachments" :key="a.id">
              <div v-if="a.url">
                <img class="mb-2" v-if="a.type === 'sticker'" width="120" :src="a.url" alt="Стикер">
                <img v-else-if="a.type === 'photo'" :src="a.url" width="200" alt="Фото"
                     @click="openFullImage(a)" class="zoom-in mb-2" @load="imageLoaded(data)">
                <a class="mb-2" v-else-if="a.type === 'link'" :href="a.url" target="_blank">{{ a.url }}</a>
                <a class="messages__attachment-story mb-2" v-else-if="a.type === 'story'" :href="a.url" target="_blank">Пользователь
                  отметил(а) вас в истории</a>
                <video class="messages__attachment-video mb-2"
                       v-else-if="a.type === 'video' && chatInfo.client.integration && chatInfo.client.integration.type !== 'vk'"
                       :src="a.url"
                       controls></video>
                <div class="d-flex flex-column mb-2"
                     v-else-if="a.type === 'video' && chatInfo.client.integration && chatInfo.client.integration.type === 'vk'">
                  <img @click="openFullImage(a)" class="zoom-in" @load="imageLoaded(data)" width="200" :src="a.url">
                  <div class="mt-2 text message-not-supported">
                    ВКонтакте не разрешает смотреть видео на других площадках. Чтобы посмотреть видео перейдите в
                    Сообщество
                  </div>
                  <a class="messages__attachment-story mt-2" :href="videoVKUrl">Перейти</a>
                </div>
                <div v-else-if="a.type === 'document'" class="messages__attachment-file mb-2">
                  <v-icon @click="downloadFile(a.url, a.text)" size="20" class="mr-2">mdi-download</v-icon>
                  <a @click="downloadFile(a.url, a.text)">{{ a.text || 'Документ' }}</a>
                </div>
                <audio
                    class="mb-2"
                    v-else-if="a.type === 'voice' || a.type === 'audio'"
                    controls
                    :src="a.url">
                </audio>
              </div>
              <div v-else>
                <div class="pointer">
                    <span v-if="a.type === 'sticker'" class="blue--text">
                      Стикер
                    </span>
                  <span v-else-if="a.type === 'photo'" class="blue--text">
                      Фотография
                    </span>
                  <span v-else-if="a.type === 'video'" class="blue--text">
                      Видео
                    </span>
                  <v-icon @click="openFileContent(data.id, a)" class="ml-2 blue--text">
                    mdi-cloud-download
                  </v-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="date" v-if="currentYear === messageYear(data.created_at)">
            <span v-if="data.changed_at" class="mr-1">(ред.)</span>
            {{ data.created_at | moment('D MMM [в] LT') }}
          </div>
          <div class="date" v-else>
            <span v-if="data.changed_at" class="mr-1">(ред.)</span>
            {{ data.created_at | moment('D MMM YYYY [в] LT') }}
          </div>
        </div>
        <div v-else class="d-flex">
          <div class="text message-not-supported">
            Сообщение не поддерживается
          </div>
        </div>
        <div class="message-actions pointer" v-if="hover && messageActions.length">
          <v-menu attach=".message-actions" :nudge-top="index === localMessages.length - 1 ? '50%' : '0'"
                  nudge-left="100" max-width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="message-actions__arrow" v-bind="attrs" v-on="on">
                mdi-chevron-down
              </v-icon>
            </template>
            <v-list dense class="profile-menu">
              <v-list-item-group>
                <v-list-item class="pl-7" v-for="item in messageActions" :key="item.name" @click.stop="item.method">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </div>
      <v-dialog
          v-model="removeMessageModal"
          max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">
            Удаление сообщения
          </v-card-title>
          <v-card-text>Вы действительно хотите удалить сообщение?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="removeMessageModal = false"
            >
              Отмена
            </v-btn>
            <v-btn
                color="red"
                text
                @click="removeMessage"
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-hover>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "MessageItem",
  props: {
    localMessages: {
      type: Array,
    },
    selectedMessages: {
      type: Array,
    },
    selectedMessageMode: {
      type: Boolean,
    },
    data: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data: () => ({
    removeMessageModal: false,
  }),
  computed: {
    ...mapState("chat", ["chatInfo", "messages"]),
    ...mapState("common", ["loading"]),
    ...mapState("user", ["profile"]),
    messageActions() {
      const hours24 = 86400000;
      let actionsArray = [];
      if (!this.selectedMessageMode) {
        const addEditAction = () => {
          actionsArray.push({
            name: 'Редактировать',
            method: () => this.$emit('editMessage', this.data),
          });
        };
        const addRemoveAction = () => {
          actionsArray.push({
            name: 'Удалить',
            method: () => this.removeMessageModal = true,
          });
        };

        const addForwardAction = () => {
          actionsArray.push({
            name: 'Переслать',
            method: () => this.$emit('selectMessage', this.data),
          });
        };

        const allowedIntegrations = ['vk', 'telegram'];

        if (this.chatInfo.client) {
          if (this.chatInfo.client.integration && allowedIntegrations.includes(this.chatInfo.client.integration.type)) {
            addForwardAction();
          }
          if ((new Date() - this.$moment(this.data.created_at).toDate() <= hours24) && this.isOwnMessage(this.data)) {
            if (this.chatInfo.client.integration && allowedIntegrations.includes(this.chatInfo.client.integration.type)) {
              addEditAction();
              addRemoveAction();
            }
          }
        } else {
          addForwardAction();
          if (this.isOwnMessage(this.data)) {
            addEditAction();
            addRemoveAction();
          }
        }
      }

      return actionsArray;
    },
    currentYear() {
      return new Date().getFullYear().toString();
    },
    videoVKUrl() {
      if (this.chatInfo.client.integration) {
        return `https://vk.com/gim${this.chatInfo.client.integration.group_id}?sel=${this.chatInfo.client.integration.user_id}`;
      }
      return '';
    },
    messageText () {
      function urlify(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        if (text) {
          return text.replace(urlRegex, function(url) {
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
          })
        }
        return '';
        // or alternatively
        // return text.replace(urlRegex, '<a href="$1">$1</a>')
      }
      return `<div class="text text-pre-line">${urlify(this.data.message)}</div>`;
    },
    notClientMessage () {
      return this.chatInfo.subtype === 'none' && !this.data.client;
    }
  },
  methods: {
    isOwnMessage(m) {
      if (this.chatInfo.subtype === 'group' || this.chatInfo.subtype === 'personal' || this.chatInfo.subtype === 'none') {
        if (m && m.user && this.profile) {
          return m.user.id === this.profile.id;
        }
        return false;
      } else {
        return !m.client;
      }
    },
    selectMessageLocal () {
      if (this.selectedMessageMode) {
        if (!this.selectedMessages.includes(this.data)) {
          this.$emit('selectMessage', this.data);
        } else {
          this.$emit('deselectMessage', this.data);
        }
      }
    },
    removeMessage() {
      this.$emit('removeMessage', this.data.id || this.data.chat_message_id);
    },
    showAvatar(prev, next) {
      if (next) {
        if (prev.client && next.user || prev.user && next.client) {
          return true;
        } else if (
            (prev.user && next.user && next.user.id === prev.user.id)
            || (prev.client && next.client)
        ) {
          return false;
        } else if (!prev.user && !prev.client && !next.user && !next.client) {
          return false;
        }
      }
      return true;
    },
    showMessageUserName(m, prev) {
      if (!m) return;
      if (!this.isOwnMessage(m) && (this.chatInfo.subtype === "group" || this.chatInfo.subtype === "none")) {
        if (prev && prev.user && m.user) {
          return prev.user.id !== m.user.id;
        }
        return true;
      }
      return false;
    },
    messageUserName(m) {
      if (m.user)
        return m.user.name;
    },
    showForwardMessageName(m) {
      if (m.user) {
        return m.user.name;
      } else if (m.client) {
        return m.client.name;
      } else {
        return 'Интеграции'
      }
    },
    isIntMessage(m) {
      return !m.client && !m.user;
    },
    openMemberPage(item) {
      if (item.client) {
        this.$router.push({path: `/clients/${item.client.id}`});
      } else if (item.user) {
        this.$router.push({path: `/users/${item.user.id}`});
      }
    },
    chatImage(i) {
      if (i.subtype === "group") {
        return false;
      } else {
        if (i.client) {
          return i.client.avatar_url;
        } else if (i.user) {
          return i.user.avatar_url;
        } else if (i.users) {
          return i.users.find(i => i.id !== this.profile.id).avatar_url;
        } else if (
            !i.client && !i.users
            && i.chat_id === this.chatInfo.id
            && this.chatInfo.client
            && this.chatInfo.client.integration) {
          return require(`@/assets/img/chat/integrations/${this.chatInfo.client.integration.type}.svg`) || false;
        } else {
          return false;
        }
      }
    },
    openFullImage(m) {
      this.$emit('openFullImage', m)
    },
    imageLoaded(i) {
      if (!i.is_old)
        this.$emit('scrollToBottom');
    },
    downloadFile(fileUrl, fileName) {
      this.$emit('downloadFile', {fileUrl: fileUrl, fileName: fileName});
    },
    openFileContent(messageId, file) {
      this.$emit('openFileContent', {messageId: messageId, file: file});
    },
    messageYear(y) {
      return this.$moment(y).format('YYYY');
    },
  },
}
</script>

<style lang="scss">
.message-actions {
  background: inherit;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 5px;

}

.messages__forwarded {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 16.9px */
  margin-bottom: 6px;
  color: #289E80;

  strong {
    font-weight: 600;
  }
}
</style>
